<template>
  <div class="page">
    <!-- INFOS PEÇA -->
    <section class="part-infos container">
      <b-field class="" label="Identificação">
        <b-input
          v-model="product.tag"
          placeholder="A tag será gerada na criação..."
          icon-pack="fas"
          icon="tag"
          disabled
        >
        </b-input>
      </b-field>

      <b-field label="Imagem do produto" v-if="product.widgets != undefined">
        <div class="card-image" v-if="product.widgets.length > 0">
            <b-carousel :indicator-inside="false" :arrow="true" :arrow-hover="false" :repeat="false" :autoplay="false">
              <b-carousel-item v-for="(widget, i) in product.widgets" :key="i">
                <b-image class="image" :src="getImageAddress(widget)"></b-image>
              </b-carousel-item>
            </b-carousel>

          </div>
      </b-field>
      <b-field>
        <b-upload
          class="button is-info has-text-centered"
          v-model="file"
          @input="upload"
          :loading="uploading"
        >
          <p>Clique aqui para enviar outra</p>
        </b-upload>
      </b-field>
      <div class="tags">
        <div class="tags">
               <span v-for="(file, index) in dropFiles"
                     :key="index"
                     class="tag is-primary" >
                {{file.name}}
                <button class="delete is-small"
                        type="button"
                        @click="deleteFile(index)">
                </button>
            </span>
        </div>
      </div>

      <b-field class="" label="Nome da Peça">
        <b-input
          v-model="product.name"
          placeholder="Peça ..."
          icon-pack="fas"
          icon="info"
        >
        </b-input>
      </b-field>

      <b-field class="" label="Categoria">
        <b-select
          placeholder="Selecione..."
          expanded
          icon-pack="fas"
          icon="cubes"
          required
          v-model="product.category.id"
        >
          <option
            v-for="option in categories"
            :value="option.id"
            :key="option.id"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>

      <b-field label="Descrição / Observações" class="">
        <b-input
          maxlength="350"
          type="textarea"
          v-model="product.description"
        ></b-input>
      </b-field>

      <b-field class="" label="Gênero">
        <b-select
          v-model="product.gender"
          placeholder="Selecione..."
          expanded
          icon-pack="fas"
          icon="transgender"
          required
        >
          <option :value="0">Unisex</option>
          <option :value="1">Masculino</option>
          <option :value="2">Feminino</option>
        </b-select>
      </b-field>
      <b-field class="" label="Tamanho">
        <b-select
          placeholder="Selecione..."
          expanded
          icon-pack="fas"
          icon="arrows-alt-h"
          required
          v-model="product.size.id"
        >
          <option v-for="option in sizes" :value="option.id" :key="option.id">
            {{ option.name }}
          </option>
        </b-select>
      </b-field>

      <b-field label="Preço de Compra" class="">
        <b-numberinput
          v-model="product.buyPrice"
          :controls="false"
          placeholder="R$"
          required
          icon-pack="fas"
          icon="dollar-sign"
          disabled
        ></b-numberinput>
      </b-field>
      <b-field label="Preço de Venda" class="">
        <b-numberinput
          v-model="product.sellPrice"
          :controls="false"
          placeholder="R$"
          required
          icon-pack="fas"
          icon="dollar-sign"
        ></b-numberinput>
      </b-field>
      <br/>
      <b-field class="">
        <b-checkbox disabled :value="product.supplier.id > 1">Peça em Consignação?</b-checkbox>
      </b-field>

      <b-field label="Fornecedora" class="">
        <b-autocomplete
          v-model="product.supplier.name"
          :data="filteredDataObj"
          field="searchField"
          placeholder="Procure pelo nome, sobrenome, instagram, Código..."
          icon-pack="fas"
          icon="female"
          disabled
        >
          <template slot="empty">Nenhum resultado encontrado</template>
        </b-autocomplete>
      </b-field>

      <b-field label="Valor Pago" class="">
        <b-numberinput
          v-model="product.paidValue"
          :controls="false"
          placeholder="Pago em Consignação"
          icon-pack="fas"
          icon="dollar-sign"
          disabled
        ></b-numberinput>
      </b-field>
    </section>

    <section class="container">
      <div class="buttons modal-card-foot">
        <span class="modal-card-actions left">
          <b-button type="is-dark is-outlined" @click="cancel">
            CANCELAR</b-button
          >
          <b-button type="is-danger" :loading="sending" @click="deletionModal">
            REMOVER</b-button
          >
        </span>
        <span class="modal-card-actions right">
          <b-button type="is-success" :loading="sending" @click="update">
            SALVAR ALTERAÇÕES</b-button
          >
        </span>

      </div>
    </section>
  </div>
</template>

<script>
import ModalAddSupplier from "@/components/modals/ModalAddSupplier";
import CategoryService from "@/services/CategoryService";
import SizeService from "@/services/SizeService";
import SupplierService from "@/services/SupplierService";
import Product from "@/models/product";
import http from "@/services/http-common";
import Supplier from "@/models/supplier";
import ModalConfirmAddition from "@/components/modals/ModalConfirmAddition";
import ProductService from "@/services/ProductService";
import transaction from "@/models/transaction";
import TransactionService from "@/services/TransactionService";
import ModalConfirmRemoval from "@/components/modals/ModalConfirmRemoval";
import ModalConfirmExclusion from "@/components/modals/ModalConfirmExclusion";

export default {
  created() {
    if(!this.$store.state.session.loggedIn){
      this.$router.push('/')
    }
    else {
      this.fetchCategories();
      this.fetchMoms();
      this.fetchSizes();
    }
  },

  mounted() {
    ProductService.details(this.$route.params.id)
      .then(response => {
        this.product = response.data;
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  },

  data() {
    /* eslint-disable  */
    return {
      sending: false,
      uploading: false,
      dropFiles: [],
      showAddMom: false,
      categories: [],
      moms: [],
      sizes: [],
      genders: [],
      name: "",
      sellerMomId: 0,
      selected: null,
      product: {},
      file: null,
      presigned: [],
      supplier: {},
      supplierSelection: null
    };
  },
  computed: {
    filteredDataObj() {
      return this.moms.filter(option => {
        return (
            option.searchField
                .toString()
                .toLowerCase()
                .indexOf(this.name.toLowerCase()) >= 0
        );
      });
    },
  },

  methods: {
    getImageAddress: function (widget) {
      return `https://api.desapegos.app/uploads/${widget.filename}`
      //return `https://images.desapegos.app/${widget.filename}`
      //return `http://192.168.1.6:3000/uploads/${widget.filename}`
      //return `http://191.252.200.226/api/uploads/?file=${product.photo_data.id}`
    },

    update: function() {
      this.sending = true;
      if (this.presigned !== []) {
        this.product.photo_data = this.presigned;
      }

      http.put(`/products/${this.product.id}`, this.product).then(result => {
        this.$router.push('/products');
        this.sending = false;
      }).catch(er => {
        console.log(er.response)
        this.sending = false;
      });
    },

    upload: function() {
      this.uploading = true;
      const formData = new FormData;
      formData.append('upload[file]', this.file);
      http.post('/uploads', formData)
          .then(resp => {
            this.presigned.push(resp.data)
            this.dropFiles.push(this.file)
            this.uploading = false;
          })
          .catch(function (errors) {
            console.log(errors)
            this.uploading = false;
          })
          .finally(this.product.photo_data = this.presigned);
      //this.toDataUrl()
    },

    deletionModal() {
      this.$buefy.modal.open({
        parent: this,
        component: ModalConfirmExclusion,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        canCancel: false,
        props: {
          product: this.product
        },
        events: {
          'success': value => {
            this.clearSelection();
          }
        }
      });
    },

    cancel: function () {
      this.$router.push('/products')
    },

    toDataUrl() {
      const vm = this.vm;
      const reader = new FileReader();
      reader.addEventListener("load", function () {
        const dataUrl = reader.result;
        vm.$emit('image', dataUrl);
        vm.$store.commit('image_preview/set', dataUrl)
      }.bind(vm), false);
      if (/\.(jpe?g|png|gif)$/i.test(vm.file.name)) {
        reader.readAsDataURL(vm.file);
      }
    },

    deleteFile: function(file) {
      this.dropFiles.splice(index, 1);
    },

    fetchCategories: function() {
      CategoryService.list()
          .then(response => {
            this.categories = response.data;
          })
          .catch(e => {
            console.log(e);
          });
    },

    fetchMoms: function() {
      SupplierService.list()
          .then(response => {
            this.moms = response.data;
            //console.log(response.data);

            this.moms.map(mom => {
              mom.searchField = mom.instagramUsername
                  ? `@${mom.instagramUsername} | ${mom.name} - [Cód. ${mom.id}]`
                  : `${mom.name} - [Cód. ${mom.id}]`;
            });
          })
          .catch(e => {
            console.log(e);
          });
    },

    fetchSizes: function() {
      SizeService.list()
          .then(response => {
            this.sizes = response.data;
          })
          .catch(e => {
            console.log(e);
          });
    },
  }
};
</script>

<style scoped>

.page {
  padding: 24px 24px;
}

.buttons {
  margin-top: 16px;
}

.modal-card-foot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.modal-card-actions {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-content: end;
}

.modal-card-actions button {
  margin-bottom: 12px;
}


</style>
