export default class Supplier {
  name: string;
  instagramUsername: string | null;
  phoneNumber: string | null;
  photo: ArrayBuffer | undefined;
  dateArrival: string;
  email: string | null;
  lastActivity: string | undefined;
  accountBalance: number;
  searchField: string | undefined;
  id: number | undefined;
  shop_admin: boolean | undefined;

  constructor(
    name = "",
    instagramUsername = null,
    photo = undefined,
    phoneNumber = '',
    accountBalance = 0.0,
    email = "",
    // eslint-disable-next-line @typescript-eslint/camelcase
    shop_admin =  false
  ) {
    this.name = name;
    this.instagramUsername = instagramUsername;
    this.phoneNumber = phoneNumber;
    this.photo = photo;
    this.email = email;
    this.accountBalance = 0.0;
    const _date = new Date;
    this.dateArrival = _date.toISOString();
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.shop_admin = false;
  }
}
