<template>
  <form action="">

    <div class="modal-card" style="width: auto">

      <header class="modal-card-head">
        <p class="modal-card-title">SOLICITAR UMA AVALIAÇÃO</p>
      </header>

      <section class="modal-card-body">
        <b-field label="Email">
          <b-input
            type="text"
            placeholder="Seu nome"
            required
            v-model="user.name"
          >
          </b-input>
        </b-field>

        <b-field label="Seu Email">
          <b-input
            type="email"
            placeholder="Minha senha"
            required
            v-model="user.password"
          >
          </b-input>
        </b-field>

        <b-field label="Seu Desapego">
          <b-input
            type="text"
            placeholder="Meu Desapego"
            required
            v-model="user.password"
          >
          </b-input>
        </b-field>
      </section>

      <footer class="modal-card-foot">
        <b-button
          class="button is-danger"
          outlined
          type="button"
          @click="$emit('close')"
          >CANCELAR</b-button
        >
        <b-button class="button is-info" @click="send">ENTRAR</b-button>
      </footer>

    </div>

  </form>
</template>

<script>
export default {
  name: "ModalTrialRequest",
  data() {
    return {
    };
  },
  methods: {
    send: function() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>

</style>
