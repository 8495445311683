<template>
  <form action="">

    <div class="modal-card" style="width: auto">

      <header class="modal-card-head">
        <p class="modal-card-title">ENTRAR</p>
      </header>

      <section class="modal-card-body">
          <b-field label="Email">
            <b-input
                type="email"
                placeholder="meu@email.com.br"
                required
                v-model="credentials.email"
            >
            </b-input>
          </b-field>

          <b-field label="Senha">
            <b-input
                type="password"
                password-reveal
                placeholder="Minha senha"
                required
                v-model="credentials.password"
            >
            </b-input>
          </b-field>

      </section>

      <footer class="modal-card-foot">
        <b-button
            class="button is-dark"
            outlined
            type="button"
            @click="$emit('close')"
        >CANCELAR</b-button
        >
        <b-button class="button is-info"
                  @click="send"
                  :loading="sending"
        >
          ENTRAR
        </b-button>
      </footer>

    </div>

  </form>
</template>

<script>
import Credential from "@/models/credentials";
import SessionService from "@/services/SessionService";

export default {
  name: "ModalCreateSession",
  data() {
    return {
      sending: false,
      credentials: {},
      user: {}
    };
  },
  methods: {
    reset: function() {
      this.credentials = new Credential();
    },
    send: function() {
      this.sending = true;
      this.user.email = this.credentials.email;
      this.user.password = this.credentials.password;
      //this.$store.dispatch("session/login", this.user).then(res => {
      //  console.log (res)
      //  this.$store.dispatch("supplier/fetchSuppliers", {
      //    perPage: 100
      //  });
      //  this.$router.push("/products");
      //});

      SessionService.logIn( this.user )
          .then( res => {
            this.$store.commit("session/SET_USER", res.data);
            this.$store.commit("session/SET_SESSION", res.headers["authorization"]);
            this.$store.dispatch("supplier/fetchSuppliers", {
              perPage: 100
            });
            this.$router.push("/products");
            this.sending = false;
            this.$emit('close');
          })
          .catch(err => {
            const notification = {
              type: "error",
              message: "there was a problem logging in: " + err.message
            };
            //dispatch("notification/add", notification, { root: true });
            this.sending = false;
            this.$emit('close');
            throw err;
          });
    },
  },
  created() {
    this.credentials = new Credential();
  }
};
</script>

<style scoped>

</style>