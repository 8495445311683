
import http from "@/services/http-common";
import Report from "@/models/report";

class ReportService {
  profit(report: Report) {
    //console.log(report)
    return http.get("/reports/profit", {
      params: {
        period: report.period
      }
    } ).then();
  }

  statistics(report: Report) {
    //console.log(report)
    return http.get("/reports/statistics", {
      params: {
        period: report.period
      }
    }  ).then();
  }

  history(report: Report) {
    //console.log(report)
    return http.get("/reports/history", {
      params: {
        year: report.year
      }
    } );
  }


}

export default new ReportService();
