<template>
  <div class="container">

    <ProfitsIncomesExpenses/>
    <hr />

    <LatestSales/>
    <hr />

    <CategoriesAndSizes/>
    <hr />

    <LatestTransactions/>
    <hr />

    <SalesHistory/>
  </div>
</template>

<script>
import CategoriesAndSizes from "@/components/graphs/CategoriesAndSizes";
import ProfitsIncomesExpenses from "@/components/graphs/ProfitsIncomesExpenses";
import SalesHistory from "@/components/graphs/SalesHistory";
import LatestTransactions from "@/components/tables/LatestTransactions";
import LatestSales from "@/components/tables/LatestSales";

export default {
  name: "Sales",
  components: {LatestSales, LatestTransactions, SalesHistory, ProfitsIncomesExpenses, CategoriesAndSizes},
  data: function() {
    return {
      isEmpty: false,
      isLoading: false,
    };
  },
  created() {
    if (!this.$store.state.session.loggedIn) {
      this.$router.push('/')
    }
  }
};
</script>

<style scoped>
.section-title {
}

.section-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.section-selector {

}

.op-results-wrapper {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.op-result-card {
  height: 160px;
  box-shadow: 0px 1px 22px -12px #607d8b;
  background-color: #fff;
  max-height: 444px;
  padding: 0 0 0 0;
  position: relative;
  border: 1px solid #dedede;
  border-radius: 3px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.sales-result-wrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.sales-result-card {
  box-shadow: 0px 1px 22px -12px #607d8b;
  background-color: #fff;
  padding: 20px 0;
  position: relative;
  border: 1px solid #dedede;
  border-radius: 3px;
  margin-bottom: 20px;
}

.latest-sales {
  margin-top: 24px;
  width: 100%;
  box-shadow: 0px 1px 22px -12px #607d8b;
  background-color: #fff;
  padding: 10px;
  position: relative;
  border: 1px solid #dedede;
  border-radius: 3px;
  min-height: 500px;
  margin-bottom: 40px;
}

.historical-results {
  margin: 25px;
  box-shadow: 0px 1px 22px -12px #607d8b;
  background-color: #fff;
  padding: 10px;
  position: relative;
  border: 1px solid #dedede;
  border-radius: 3px;
  margin-bottom: 40px;
  margin: 25px auto;
}

.section-title {
  font-family: sans-serif;
  margin-top: 20px;
  padding-top: 12px;
  padding-left: 20px;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  color: #4a4a4a;
}

.apexcharts-tooltip {
  background: #f3f3f3;
  color: orange;
}
</style>
