<template>
  <form action="">

    <div class="modal-card" style="width: auto">

      <header class="modal-card-head">
        <p class="modal-card-title" v-if="editMode">EDITAR TAMANHO</p>
        <p class="modal-card-title" v-if="!editMode" >ADICIONAR TAMANHO</p>
      </header>

      <section class="modal-card-body">

          <br />
        <b-field label="Abreviação">
          <b-input
              placeholder="UN"
              required
              v-model="size.name"
          >
          </b-input>
        </b-field>

        <b-field label="Tamanho / Idade">
          <b-input
              placeholder="Único"
              required
              v-model="size.age_size"
          >
          </b-input>
        </b-field>

          <br />


      </section>

      <footer class="modal-card-foot">
        <section>
          <b-button
              class="button is-dark"
              outlined
              type="button"
              @click="$emit('close')"
          >CANCELAR</b-button
          >
        </section>
        <section>
          <b-button
              class="button is-success"
              @click="save"
              :loading="sending"
          >SALVAR</b-button
          >
        </section>
      </footer>

    </div>

  </form>
</template>

<script>
import SizeService from "@/services/SizeService";
import Size from "@/models/size";

export default {
  name: "ModalEditCreateSizes",
  props: {
    size: {
      type: Size
    }
  },
  computed: {
    editMode: function() {
      return this.size.id >= 1;
    },
  },
  data() {
    return {
      sending: false,
    };
  },
  methods: {
    save: function () {
      if (this.editMode)
      {
        SizeService.update(this.size).then(() => {
          this.$emit("success");
          this.$emit("close");

        });
      }
      else {
        SizeService.create(this.size).then(() => {
          this.$emit("success");
          this.$emit("close");
        });
      }

    }
  }
}
</script>

<style scoped>

.modal-card-foot {
  display: flex;
  justify-content: space-between;
}

</style>