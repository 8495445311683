/* eslint-disable  */ 

export default class Product {
  
  //tag: string | null;
  name: string | null;
  description: string | null;
  arrivalDate: string | null;
  gender: number | null;
  size_id: number | null;
  category_id: number | null;
  buyPrice: number | null;
  sellPrice: number | null;
  supplier_id: number;
  paidValue: number | null;
  widgets: any | null;


  constructor(
    //tag = null,
    name = null,
    description = null,
    supplier_id = 1,
    buyPrice = null,
    sellPrice = null,
    gender = null,
    size_id = null,
    category_id = null,
    arrivalDate = null,
    paidValue = null,
    widgets = []

    // initializing by default with empty values
  ) {
    //this.tag = tag;
    this.name = name;
    this.description = description;
    this.supplier_id = supplier_id;
    this.buyPrice = buyPrice;
    this.sellPrice = sellPrice;
    this.gender = gender;
    this.size_id = size_id;
    this.category_id = category_id;
    this.arrivalDate = arrivalDate;
    this.paidValue = paidValue;
    this.widgets = widgets;

  }
}
