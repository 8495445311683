<template>
  <div class="page">
    <section class="instagram-auth" v-if="true">
      <b>ASSOCIE SEU INSTAGRAM</b>
      <br/>
      <p>
        Este sistema possui integração com o Instagram para facilitar a sua vida!
      </p>
      <p>
        Apesar de ser um recurso experimental, você já pode testá-lo nesta demonstração.
      </p>
      <p>
        Para conceder ao aplicativo as permissões necessárias de acesso e publicação, utilize o botão abaixo.
      </p>
      <br/>
      <p><b>REQUISITOS:</b></p>
      <ul>
        <li>- Conta <u>comercial</u> do Instagram. Contas pessoais e de criador não são suportadas.</li>
        <li>- Página no Facebook associada com a sua conta do Instagram</li>
        <li></li>
      </ul>

      <br/>
      <span>
        <a :href="fbAuthUrl">
          <b-button class="is-info is-outlined">
            CONECTAR COM O FACEBOOK
          </b-button>
        </a>
      </span>
      <span>
        <a :href="igAuthUrl">
          <b-button class="is-primary is-outlined">
            CONECTAR COM O INSTAGRAM
          </b-button>
        </a>
      </span>
      <br/>
    </section>

    <hr/>
  <h1> PUBLICAÇÕES DE <b>{{igUser.toUpperCase()}}</b>  </h1>
    <section>

      <br/>

      <h2>#TODO:</h2>
      <ul>
        <li>- LISTAR TODOS OS POSTS </li>
        <Li>- OCULTAR MSG E BOTÃO P/ CONECTAR SE JÁ CONECTADO</Li>
        <Li>- OCULTAR POSTS CASO NÃO CONECTADO AINDA</Li>
        <Li>- ALTERAR IMAGENS PARA USAR IMGS DO INSTAGRAM MESMO</Li>
        <Li>- VERIFICAR PÁGINA ''SUPPLIERS'' SE ESTÉ PEGANDO DADOS APENAS DO TENANT QUE DEVERIA</Li>
        <Li>- CASO FOR USAR GRAPH-API, É POSSÍVEL ADICIONAR CARROSSEL. VERIFICAR</Li>
      </ul>

    </section>
  </div>
</template>

<script>
import http from "@/services/http-common";
export default {
  name: "InstagramPostsPage",
  data: function () {
    return {
      igScopes: 'user_profile,user_media',
      igClient: '',
      igState: '',
      igRedirectUrl: '',
      igUser: '. . .',
      igAuthUrl: '',
      fbScopes: 'email',
      fbClient: '',
      fbState: '',
      fbRedirectUrl: '',
      fbUser: '. . .',
      fbAuthUrl: ''
    }
  },
  created: function () {
    if (!this.$store.state.session.loggedIn) {
      this.$router.push('/401')
    }
    // eslint-disable-next-line @typescript-eslint/camelcase
    // eslint-disable-next-line @typescript-eslint/camelcase
    http.get("/auth/instagram/initialize")
        .then( res =>
        {
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.igState = res.data.ig_state
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.igClient = res.data.ig_client_id
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.igRedirectUrl = res.data.ig_redirect_uri
          this.igScopes = res.data.ig_scopes
          this.igAuthUrl = `https://api.instagram.com/oauth/authorize?client_id=${this.igClient}&redirect_uri=${this.igRedirectUrl}&state=${this.igState}&scope=${this.igScopes}&response_type=code`
        })
    http.get("/auth/facebook/initialize")
        .then( res =>
        {
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.fbState = res.data.fb_state
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.fbClient = res.data.fb_client_id
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.fbRedirectUrl = res.data.fb_redirect_uri
          this.fbScopes = res.data.fb_scopes
          this.fbAuthUrl = `https://www.facebook.com/v13.0/dialog/oauth?client_id=${this.fbClient}&redirect_uri=${this.fbRedirectUrl}&state=${this.fbState}&scope=${this.fbScopes}&response_type=code`
        })

    // eslint-disable-next-line @typescript-eslint/camelcase
    http.get("/auth/instagram/")
        .then(res => { this.igUser = res.data.username })
    http.get("/auth/facebook/")
        .then(res => {console.log(res)})
  },
}
</script>

<style scoped>

.page {
  padding: 24px 24px;
}

</style>