<template>
  <section>
    <p class="section-title">HISTÓRICO DE VENDAS</p>
    <div class="latest-sales">
      <!-- SALES TABLE   -->
      <b-table
          :data="isEmpty ? [] : sales"
          :mobile-cards="true"
          icon-pack="fa"
          :paginated="true"
          :per-page="perPage"
          :current-page="currentPage"
          :pagination-simple="false"
          pagination-position="both"
          :default-sort="defaultSort"
          :default-sort-direction="defaultSortDirection"
          ria-next-label="Próxima página"
          aria-previous-label="Página anterior"
          aria-page-label="Página"
          aria-current-label="Página atual"
          searchable

      >
        <b-table-column
            field="id"
            label="ID"
            centered
            numeric
            v-slot="props"
            :visible="false"
            sortable
        >
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="created_at" label="Data" centered v-slot="props" sortable>
            <span class="has-text-weight-semibold">
              {{ moment(props.row.created_at).format("DD/MM/YYYY") }}
            </span>
        </b-table-column>

        <b-table-column
            field="product_id"
            label="Produtos"
            centered
            v-slot="props"
        >
            <span class="">
              <span
                  class="tag is-primary mx-1 has-text-white"
                  v-for="transaction in props.row.transactions"
                  :key="transaction.id"
              >
                {{ transaction.product.tag }}
              </span>
            </span>
        </b-table-column>

        <b-table-column field="buyer_id" label="Comprador" centered v-slot="props">
            <span v-if="props.row.buyer_id !== null">
              {{ props.row.buyer.name }}
            </span>
          <span v-else> -- </span>
        </b-table-column>

         <b-table-column field="parts" label="Valor Peças" centered v-slot="props">
           R$ {{ props.row.transactions.reduce((accumulator, current) => accumulator + current.balance, 0)  | formatValue }}
        </b-table-column>

        <b-table-column field="discount" label="Desconto" centered v-slot="props">
          R$ {{ props.row.discount | formatValue }}
        </b-table-column>

        <b-table-column field="freight" label="Frete" centered v-slot="props">
          R$ {{ props.row.freight | formatValue }}
        </b-table-column>

        <b-table-column
            field="balance"
            label="Total Recebido"
            centered
            v-slot="props"
        >
            <span
                class="has-text-weight-semibold has-text-success-dark"
            >
              R$ {{ props.row.freight - props.row.discount + props.row.transactions.reduce((accumulator, current) => accumulator + current.balance, 0)  | formatValue }}
            </span>
        </b-table-column>

        <b-table-column label="Ações" centered v-slot="props">
          <span class="actions" v-if="props.row.status == null">
            <b-button class="is-danger actions-button is-small" :loading="sending" @click="cancelSale(props.row)" outlined> CANCELAR </b-button>
            <b-button class="is-success actions-button is-small" :loading="sending" @click="confirmPayment(props.row)" outlined> CONFIRMAR </b-button>
          </span>
          <span class="actions" v-if="false /*props.row.status != null*/">
            <b-button class="is-info actions-button is-small" :loading="sending" @click="detailSale(props.row)" outlined> VISUALIZAR </b-button>
          </span>
        </b-table-column>

      </b-table>
    </div>
  </section>
</template>

<script>
import SalesService from "@/services/SalesService";
import Sale from "@/models/sale";

export default {
  name: "LatestSales",
  data: function() {
    return {
      isEmpty: false,
      isLoading: false,
      currentPage: 1,
      perPage: 25,
      defaultSort: 'created_at',
      defaultSortDirection: 'desc',
      sales: [],
      sending: false
    };
  },
  filters: {
    convertOperation: function(value) {
      if (value === 1) return "Compra";
      if (value === 2) return "Venda";
      if (value === 3) return "Consignação";
      if (value === 4) return "Devolução";
      if (value === 5) return "Acerto";
      else return "";
    },
    convertGender: function(value) {
      if (value === 0) return "Unisex";
      if (value === 1) return "Masculino";
      if (value === 2) return "Feminino";
      else return "";
    },
    formatValue: function(value) {
      return value.toFixed(2);
    }
  },
  methods: {
    fetchSales: function() {
      SalesService.list()
          .then(response => {
            this.sales = response.data;
          })
          .catch(e => {
            console.log(e);
          });
    },
    confirmPayment: function (sale) {
      this.sending = true;
      sale.status = 1;
      SalesService.update(sale)
          .then(response => {
            this.sales = response.data;
            this.sending = false;
            alert('Pagamento confirmado!');
          })
          .catch(e => {
            console.log(e);
            this.sending = false;
          });
    },
    cancelSale: function (sale) {
      this.sending = true;

      SalesService.delete(sale.id)
          .then(response => {
            this.sales = response.data;
            this.sending = false;
            alert('Venda cancelada!');
          })
          .catch(e => {
            console.log(e);
            this.sending = false;
          });

    },

    detailSale: function () {
      this.sending = true;

      setTimeout(() => {  console.log("World!"); }, 2000);
      alert('Visualizando venda!');

      this.sending = false;
    }
  },
  created() {
    this.fetchSales();
  },
};
</script>

<style scoped>

.section-title {
  font-family: sans-serif;
  margin-top: 20px;
  padding-top: 12px;
  padding-left: 20px;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  color: #4a4a4a;
}

.latest-sales {
  margin-top: 24px;
  width: 100%;
  box-shadow: 0px 1px 22px -12px #607d8b;
  background-color: #fff;
  padding: 10px;
  position: relative;
  border: 1px solid #dedede;
  border-radius: 3px;
  min-height: 500px;
  margin-bottom: 40px;
}

.actions {
  display: flex;
  justify-content: center;
}

.actions-button {
  margin-left: 10px;
}

</style>
