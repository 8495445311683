<template>
  <form action="">

    <div class="modal-card" style="width: auto">

      <header class="modal-card-head" >
        <p class="modal-card-title">CONFIRMAR DEVOLUÇÃO</p>
      </header>

      <section class="modal-card-body">

        <div v-if="hasProductsToReturn">
          <br/>
          <p class="has-text-danger">Você está removendo itens do seu estoque!</p>
          <br/>
          <p>Ao confirmar esta ação, os produtos que estão em consignação serão removidos e o valor pago será
            adicionado como débito com o fornecedor.</p>
          <br/>
          <p>Confirme a remoção dos produtos de tag:</p>
          <p>
            <span v-for="product in products" :key="product.id" class="has-text-weight-semibold has-text-danger">
              {{ product.supplier.id > 1 ? product.tag + ', ' : '' }}
            </span>
          </p>
          <br/>
          <p>Os produtos selecionados que não estavam em consignação serão ignorados.</p>
          <br/>
        </div>

        <div v-else-if="!hasProductsToReturn">
          <br/>
          <span class="has-text-weight-semibold has-text-danger">Não é possível devolver nenhum dos produtos selecionados</span>
          <br/>
          <br/>
          <p>Apenas itens que foram obtidos por meio de consignação podem ser removidos do estoque.
            Para remover um produto que foi diretamente comprado, remova-o por meio de uma venda</p>
          <br/>
          <p>Os produtos selecionados foram ignorados.</p>
          <br/>

        </div>

        <br/>

      </section>

      <footer class="modal-card-foot">
        <b-button
          class="button is-dark"
          outlined
          type="button"
          @click="$emit('close')"
          >CANCELAR</b-button
        >
        <b-button class="button is-info" @click="confirmRemoval" :loading="sending" :disabled="!hasProductsToReturn">DEVOLVER</b-button>
      </footer>

    </div>

  </form>
</template>

<script>
import transaction from "@/models/transaction";
import TransactionService from "@/services/TransactionService";
import product from "@/models/product";

export default {
  name: "ModalConfirmRemoval",
  props: ["products"],
  methods: {
    confirmRemoval: function () {
      this.sending = true
      this.products.forEach((product, index) => {
        if (product.supplier.id > 1) {
          const trans = new transaction( 4, product.id)
          TransactionService.create(trans).then( () => {
            this.$store.dispatch("product/removeProduct", product.id).then( () => {
              const notif = this.$buefy.notification.open({
                duration: 1500,
                message: `O produto "${product.name}" foi removido com sucesso!.`,
                type: 'is-success',
                hasIcon: true
              })
              this.sending = false
            })
            this.$emit('success');
            this.$emit('close');
          }).catch((e) => {
            const notif = this.$buefy.notification.open({
              duration: 1500,
              message: `O produto "${product.name}" não foi removido.`,
              type: 'is-danger',
              hasIcon: true
            })
            this.sending = false
            this.$emit('close');
          })
        }
        else {
          console.log("o produto " + product.tag + "não é consignado e portanto não pode ser devolvido.")
          this.$emit('close');
        }
      });
    }
  },
  data() {
    return {
      sending: false,
    };
  },
  computed: {
    hasProductsToReturn: function () {
      const returnable = this.products.filter(p => p.supplier.id > 1)
      if (returnable.length > 0) {
        return true
      }
      else {
        return false
      }
    }
  }
};
</script>

<style scoped>
.modal-card-foot {
  display: flex;
  justify-content: space-between;
}
</style>
