<template>
  <form action="">

    <div class="modal-card" style="width: auto">

      <header class="modal-card-head">
        <p class="modal-card-title">CONFIRMAR ACERTO</p>
      </header>

      <section class="modal-card-body">
        <div v-if="hasValueToReturn">
          <br />
          <p class="has-text-info">
            Você possui alguns valores em aberto com
            <span class="has-text-weight-semibold">{{
              this.supplier.name
            }}</span>
          </p>
          <br />
          <p>Confira as peças em aberto e seus respectivos valores:</p>
          <br />

          <b-field grouped group-multiline>
            <div
              class="control"
              v-for="product in productsToPay"
              :key="product.id"
            >
              <b-tag
                close-type="is-danger"
                attached
                closable
                aria-close-label="Close tag"
                @close="removeProduct(product.tag)"
              >
                <span class="has-text-weight-bold">{{ product.tag }}</span
                >:
                <span class="has-text-danger">
                  R$
                  {{
                    (product.buyPrice - product.paidValue) | formatValue
                  }}</span
                >
              </b-tag>
            </div>
          </b-field>

          <br />
          <p>
            O total deste acerto é de:
            <span class="has-text-danger"
              >R$ {{ valueToReturn | formatValue }}</span
            >
          </p>

          <br />
          <p>
            Ao confirmar esta ação, os produtos selecionados serão considerados
            pagos.
          </p>
          <p>O valor pago será deduzido do débito atual com o fornecedor.</p>
          <br />
        </div>

        <div v-else-if="!hasValueToReturn">
          <br />
          <span class="has-text-weight-semibold has-text-danger"
            >Não é possível realizar o acerto</span
          >
          <br />
          <br />
          <p>Não há itens em aberto para fazer o acerto.</p>
          <br />
          <p></p>
          <br />
        </div>

      </section>

      <footer class="modal-card-foot">
        <b-button
          class="button is-danger"
          outlined
          type="button"
          @click="$emit('close')"
          >CANCELAR</b-button
        >
        <b-button
          class="button is-success"
          @click="confirmPayment"
          :disabled="!hasValueToReturn"
          :loading="sending"
          >CONFIRMAR</b-button
        >
      </footer>

    </div>

  </form>
</template>

<script>
import TransactionService from "@/services/TransactionService";
import SupplierService from "@/services/SupplierService";
import transaction from "@/models/transaction";

export default {
  name: "ModalConfirmSupplierPayment",
  props: ["supplierId"],
  data() {
    return {
      sending: false,
      products: [],
      supplier: {},
      productsToPay: []
    };
  },
  methods: {
    confirmPayment: function() {
      this.sending = true
      this.productsToPay.forEach((product, index) => {
        if (product.buyPrice > product.paidValue) {
          const trans = new transaction(5, product.id);
          TransactionService.create(trans)
            .then(() => {
              const notif = this.$buefy.notification.open({
                duration: 1500,
                message: `Acerto registrado!`,
                type: "is-info"
              });
              this.$store.dispatch("supplier/updateSupplier", this.supplierId);
              this.sending = false;
              this.$emit("success");
              this.$emit("close");
            })
            .catch(() => {
              const notif = this.$buefy.notification.open({
                duration: 1500,
                message: `Oops! Ocorreu algum erro ao registrar o acerto`,
                type: "is-danger"
              });
              this.sending = false;
              this.$emit("close");
            });
        } else {
          this.$emit("close");
          console.log(
            "o produto " +
              product.tag +
              "não é consignado e portanto não pode ser acertado."
          );
        }
      });
    },
    removeProduct: function(tag) {
      this.productsToPay = this.productsToPay.filter(f => f.tag != tag);
    }
  },
  mounted() {
    SupplierService.details(this.supplierId)
      .then(response => {
        this.supplier = response.data;
        this.products = this.supplier.products.filter(
          p => p.payment_status === 2 && p.buyPrice > p.paidValue
        );
        this.productsToPay = this.products;
      })
      .catch(e => {
        const notif = this.$buefy.notification.open({
          duration: 1500,
          message: `Oops! Ocorreu algum problema ao obter os detalhes do fornecedor.`,
          type: "is-danger"
        });
      });
  },
  computed: {
    hasValueToReturn() {
      return this.productsToPay != null && this.productsToPay.length > 0;
    },
    valueToReturn() {
      return this.productsToPay
        .map(p => p.buyPrice - p.paidValue)
        .reduce(function(a, b) {
          return a + b;
        }, 0);
    }
  },
  filters: {
    formatValue: function(value) {
      return value.toFixed(2);
    }
  }
};
</script>

<style scoped>
.modal-card-foot {
  display: flex;
  justify-content: space-between;
}
</style>
