import Size from "@/models/size"
import http from "@/services/http-common";

class SizeService {
    list() {
        return http.get("/sizes");
    }

    create(size: Size) {
        return http.post("/sizes", size)
    }

    update(size: any) {
        return http.put(`/sizes/${size.id}`, size)
    }

    remove(id: number) {
        return http.delete(`/sizes/${id}`)
    }
}

export default new SizeService();
