var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('p',{staticClass:"section-title"},[_vm._v("HISTÓRICO DE TRANSAÇÕES / PEÇAS")]),_c('div',{staticClass:"latest-sales"},[_c('b-table',{attrs:{"data":_vm.isEmpty ? [] : _vm.transactions,"mobile-cards":true,"icon-pack":"fa","paginated":true,"per-page":_vm.perPage,"current-page":_vm.currentPage,"pagination-simple":false,"pagination-position":"both","ria-next-label":"Próxima página","aria-previous-label":"Página anterior","aria-page-label":"Página","aria-current-label":"Página atual","searchable":""}},[_c('b-table-column',{attrs:{"field":"id","label":"ID","centered":"","numeric":"","visible":false,"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.id)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"date","label":"Data","centered":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{staticClass:"has-text-weight-semibold"},[_vm._v(" "+_vm._s(_vm.moment(props.row.date).format("DD/MM/YYYY"))+" ")])]}}])}),_c('b-table-column',{attrs:{"field":"operation","label":"Operação","centered":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{class:props.row.operation == 1 ||
            props.row.operation == 3 ||
            props.row.operation == 5
              ? 'has-text-danger'
              : 'has-text-success-dark'},[_vm._v(" "+_vm._s(_vm._f("convertOperation")(props.row.operation))+" ")])]}}])}),_c('b-table-column',{attrs:{"field":"product_id","label":"TAG","centered":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{},[_c('span',{staticClass:"tag is-primary mx-1 has-text-white"},[_vm._v(_vm._s(props.row.product.tag))])])]}}])}),_c('b-table-column',{attrs:{"field":"product.name","label":"Produto","centered":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{},[_vm._v(" "+_vm._s(props.row.product.name)+" ")])]}}])}),_c('b-table-column',{attrs:{"field":"product.category.name","label":"Gênero / Categoria","centered":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{},[_vm._v(" "+_vm._s(props.row.product.category.name)+", "+_vm._s(_vm._f("convertGender")(props.row.product.gender))+" ")])]}}])}),_c('b-table-column',{attrs:{"field":"product.size.name","label":"Tamanho","centered":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{},[_vm._v(" "+_vm._s(props.row.product.size.name)+" ")])]}}])}),_c('b-table-column',{attrs:{"field":"balance","label":"Resultado","centered":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{staticClass:"has-text-weight-semibold",class:props.row.operation == 1 ||
            props.row.operation == 3 ||
            props.row.operation == 5
              ? 'has-text-danger'
              : 'has-text-success-dark'},[_vm._v(" R$ "+_vm._s(_vm._f("formatValue")(props.row.balance))+" ")])]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }