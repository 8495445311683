<template>
<div>
  <h1> PLANOS & PREÇOS </h1>
</div>
</template>

<script>
export default {
name: "PricesLandingPage"
}
</script>

<style scoped>

</style>