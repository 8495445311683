<template>
  <div class="page">
    <!--
    <b>CONFIGURAÇÕES DO SISTEMA</b>

    <hr/>
-->
    <section class="sizes-cfgs">
      <br/>
      <SizesTable/>
      <br/>
      <hr/>
    </section>

    <section class="category-config">
      <br/>
      <CategoriesTable/>
      <br/>
      <hr/>
    </section>


  </div>
</template>

<script>
import CategoriesTable from "@/components/tables/configurations/CategoriesTable";
import SizesTable from "@/components/tables/configurations/SizesTable";

import http from "@/services/http-common";
export default {
  name: "ConfigurationsPage",
  components: {SizesTable, CategoriesTable},
  created: function () {
    if(!this.$store.state.session.loggedIn){
      this.$router.push('/401')
    }
  },
  methods:{
  }
}
</script>

<style scoped>
.page {
  margin-bottom: 35px;
  padding: 24px 24px;
}
</style>