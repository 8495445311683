<template>
  <section>

    <div class="section-header">
      <h1 class="section-title">RESULTADOS HISTÓRICOS</h1>
      <section>
        <b-field>
          <b-select
            class="section-selector is-small"
            v-model="year"
            placeholder="Ano Corrente"
          >
            <option value="2021">2021</option>
            <option value="2020">2020</option>
          </b-select>
        </b-field>
      </section>
    </div>

    <div class="history">
      <article class="historical-results">
        <apexchart
          type="bar"
          ref="chart"
          :options="historyChartOptions"
          :series="historyGraphSeries"
        ></apexchart>
      </article>
    </div>

  </section>
</template>

<script>
import ReportService from "@/services/ReportService";
import VueApexCharts from "apexcharts";

export default {
  name: "SalesHistory",

  data: function() {
    return {
      isEmpty: false,
      isLoading: true,
      year: null,
      period: null,

      historyChartOptions: {
        chart: {
          id: "historical-results-graph",
          height: 300
        },
        xaxis: {
          format: "MM/yy",
          type: "datetime",
          labels: {
            datetimeFormatter: {
              month: "MM / yy"
            }
          }
        },
        yaxis: {
          show: false
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#333"]
          },
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5,
            color: "#FFF"
          }
        }
      },
      historyGraphSeries: [
        {
          name: "Vendas no Mês",
          data: []
        }
      ]
    };
  },

  methods: {
    fetchData: function(report) {
      ReportService.history(report)
        .then(response => {
          //console.log(response.data);
          this.historyGraphSeries[0].data = Object.values(response.data.sold);

          /**** UPDATE GRAPH PROPERTIES ****/

          VueApexCharts.exec("historical-results-graph", "updateOptions", {
            labels: Object.keys(response.data.sold)
          });

          /**** END: UPDATE GRAPH TITLES ****/
          this.isLoading = false
        })
        .catch(e => {
          console.log(e);
        });
    }
  },

  watch: {
    year: function(val, oldVal) {
      this.fetchData({ year: val });
    }
  },

  mounted() {
    this.fetchData({});
  }
};
</script>

<style scoped>

.section-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.section-title {
  font-family: sans-serif;
  margin-top: 20px;
  padding-top: 12px;
  padding-left: 20px;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  color: #4a4a4a;
}

.historical-results {
  margin: 25px;
  box-shadow: 0px 1px 22px -12px #607d8b;
  background-color: #fff;
  padding: 10px;
  position: relative;
  border: 1px solid #dedede;
  border-radius: 3px;
  margin-bottom: 40px;
  margin: 25px auto;
}

</style>
