<template>
  <section>

    <div class="section-header">

      <h1 class="section-title">RESLULTADOS GERAIS</h1>
      <b-field>
        <b-select
          class="section-selector is-small"
          v-model="period"
          placeholder="Mensal"
        >
          <option value="year">Anual</option>
          <option value="month">Mensal</option>
          <option value="week">Semanal</option>
        </b-select>
      </b-field>
    </div>

    <div class="op-results-wrapper">
      <article class="op-result-card">
        <apexchart
          height="160"
          :options="soldGraphOptions"
          :series="soldGraphSeries"
        ></apexchart>
      </article>

      <article class="op-result-card">
        <apexchart
          height="160"
          :options="purchasesGraphOptions"
          :series="purchasesGraphSeries"
        ></apexchart>
      </article>

      <article class="op-result-card">
        <apexchart
          height="160"
          :options="profitGraphOptions"
          :series="profitGraphSeries"
        ></apexchart>
      </article>

    </div>

  </section>
</template>

<script>
import ReportService from "@/services/ReportService";
import VueApexCharts from "apexcharts";

export default {
  name: "ProfitsIncomesExpenses",
  data: function() {
    return {
      isEmpty: false,
      isLoading: false,
      period: 'month',
      report: {
        period: 'month'
      },

      soldGraphOptions: {
        chart: {
          id: "sold-results-graph",
          group: "sparklines",
          type: "area",
          height: 160,
          sparkline: {
            enabled: true
          }
        },
        series: [
          {
            name: "Lucro"
          },
          {
            name: "Compras"
          }
        ],
        stroke: {
          curve: "straight"
        },
        fill: {
          opacity: 1
        },
        labels: [...Array(31).keys()].map(n => `2018-09-0${n + 1}`),
        yaxis: {
          min: 0
        },
        xaxis: {
          type: "datetime"
        },
        //colors: ["#4585d3","#DCE6EC"],
        colors: ["#abb2b6", "#DCE6EC"],
        title: {
          text: "R$ --",
          offsetX: 30,
          style: {
            fontSize: "24px",
            cssClass: "apexcharts-yaxis-title"
          }
        },
        subtitle: {
          text: "Vendas",
          offsetX: 30,
          style: {
            fontSize: "14px",
            cssClass: "apexcharts-yaxis-title"
          }
        }
      },
      soldGraphSeries: [
        {
          name: "Valores Recebidos [R$]",
          data: []
        },
        {
          name: "Peças Vendidas [un]",
          data: []
        }
      ],

      purchasesGraphOptions: {
        chart: {
          id: "purchases-results-graph",
          group: "sparklines",
          type: "area",
          height: 160,
          sparkline: {
            enabled: true
          }
        },
        stroke: {
          curve: "straight"
        },
        fill: {
          opacity: 1
        },
        series: [
          {
            name: "Investimento"
          },
          {
            name: "Unidades"
          }
        ],
        labels: [...Array(24).keys()].map(n => `2018-09-0${n + 1}`),
        yaxis: {
          min: 0
        },
        xaxis: {
          type: "datetime"
        },
        //colors: ["#d3455b","#DCE6EC"],
        colors: ["#abb2b6", "#DCE6EC"],
        title: {
          text: "R$ --",
          offsetX: 30,
          style: {
            fontSize: "24px",
            cssClass: "apexcharts-yaxis-title"
          }
        },
        subtitle: {
          text: "Compras",
          offsetX: 30,
          style: {
            fontSize: "14px",
            cssClass: "apexcharts-yaxis-title"
          }
        }
      },
      purchasesGraphSeries: [
        {
          name: "Investimento [R$]",
          data: []
        },
        {
          name: "Peças Compradas [un]",
          data: []
        }
      ],

      profitGraphOptions: {
        chart: {
          id: "profit-results-graph",
          group: "sparklines",
          type: "area",
          sparkline: {
            enabled: true
          }
        },
        stroke: {
          curve: "straight"
        },
        fill: {
          opacity: 1
        },
        series: [{ name: "Lucro" }, { name: "Unidades" }],
        xaxis: {
          type: "datetime"
        },
        yaxis: {
        },
        colors: ["#d3455b"],
        title: {
          text: "R$ --",
          offsetX: 30,
          style: {
            fontSize: "24px",
            cssClass: "apexcharts-yaxis-title"
          }
        },
        subtitle: {
          text: "Lucro",
          offsetX: 30,
          style: {
            fontSize: "14px",
            cssClass: "apexcharts-yaxis-title"
          }
        }
      },
      profitGraphSeries: [
        {
          name: "Lucro das Transações [R$]",
          data: []
        }
      ]
    };
  },
  methods: {
    fetchData: function(report) {
      ReportService.profit(report)
        .then(response => {
          //console.log(response.data);
          this.soldGraphSeries[1].data = Object.values(response.data.sales_un);
          this.soldGraphSeries[0].data = Object.values(response.data.sales);
          this.purchasesGraphSeries[1].data = Object.values(response.data.purchases_un);
          this.purchasesGraphSeries[0].data = Object.values(response.data.purchases).map(Math.abs);
          this.profitGraphSeries[0].data = Object.values(response.data.profit);

          /**** UPDATE GRAPH TITLES ****/

          VueApexCharts.exec("sold-results-graph", "updateOptions", {
            title: {
              text: `R$ ${response.data.value_sold.toFixed(2)}`
            },
            labels: Object.keys(response.data.sales)
          });

          VueApexCharts.exec("purchases-results-graph", "updateOptions", {
            title: {
              text: `R$ ${response.data.value_purchased.toFixed(2)}`
            },
            labels: Object.keys(response.data.purchases)
          });

          VueApexCharts.exec("profit-results-graph", "updateOptions", {
            title: {
              text: `R$ ${response.data.value_profit.toFixed(2)}`
            },
            labels: Object.keys(response.data.profit)
          });

          /**** END: UPDATE GRAPH TITLES ****/

          //console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  mounted() {
    this.fetchData({});
  },
  watch: {
    period: function(val, oldVal) {
      this.fetchData({ period: val} );
    }
  },

};
</script>

<style scoped>

.section-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.section-title {
  font-family: sans-serif;
  margin-top: 20px;
  padding-top: 12px;
  padding-left: 20px;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  color: #4a4a4a;
}

.op-results-wrapper {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.op-result-card {
  height: 160px;
  box-shadow: 0px 1px 22px -12px #607d8b;
  background-color: #fff;
  max-height: 444px;
  padding: 0 0 0 0;
  position: relative;
  border: 1px solid #dedede;
  border-radius: 3px;
  margin-bottom: 10px;
  margin-top: 10px;
}

</style>
