<template>
  <section>
    <div class="table-header">
      <p class="section-title">CADASTRO DE CATEGORIAS</p>
      <b-button class="is-success actions-button is-small" @click="add()" outlined> ADICIONAR </b-button>
    </div>
    <div class="table-content">
      <!-- SALES TABLE   -->
      <b-table
          :data="isEmpty ? [] : categories"
          :mobile-cards="true"
          icon-pack="fa"
          default-sort="id"
      >
        <b-table-column
            field="id"
            label="ID"
            centered
            numeric
            v-slot="props"
            :visible="false"
        >
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="name" label="Nome" centered v-slot="props">
            <span class="has-text-weight-semibold">
              {{ props.row.name }}
            </span>
        </b-table-column>


        <b-table-column f label="Ações" centered v-slot="props">
          <span class="actions">
            <b-button class="is-info actions-button is-small" @click="edit(props.row)" outlined> EDITAR </b-button>
            <b-button class="is-danger actions-button is-small" @click="remove(props.row)" outlined> REMOVER </b-button>
          </span>
        </b-table-column>

      </b-table>
    </div>
  </section>
</template>

<script>
import CategoryService from "@/services/CategoryService";
import Size from "@/models/size";
import ModalEditCreateSizes from "@/components/modals/configurations/ModalEditCreateSizes";
import ModalConfirmConfigurationRemoval from "@/components/modals/configurations/ModalConfirmConfigurationRemoval";
import ModalEditCreateCategories from "@/components/modals/configurations/ModalEditCreateCategories";

export default {
  name: "CategoriesTable",
  data: function() {
    return {
      isEmpty: false,
      isLoading: false,
      currentPage: 1,
      perPage: 100,
      categories: [],
    };
  },
  filters: {
  },
  methods: {
    fetchCategories: function() {
      CategoryService.list()
          .then(response => {
            this.categories = response.data;
          })
          .catch(e => {
            console.log(e);
          });
    },
    add: function() {
      this.size = new Size();
      this.$buefy.modal.open({
        parent: this,
        component: ModalEditCreateCategories,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        canCancel: false,
        trapFocus: true,
        props: {
          category: {}
        },
        events: {
          success: () => {
            this.fetchCategories();
          }
        }
      });
    },
    edit: function(category) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalEditCreateCategories,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        canCancel: false,
        trapFocus: true,
        props: {
          category: category
        },
        events: {
          success: () => {
            this.fetchCategories();
          }
        }
      });
    },
    remove: function(category) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalConfirmConfigurationRemoval,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        canCancel: false,
        trapFocus: true,
        props: {
          object: category,
          type: 'category',
          desc: 'Categoria',
          id: category.id
        },
        events: {
          success: () => {
            this.fetchCategories();
          }
        }
      });
    }
  },
  created() {
    this.fetchCategories();
  },
};
</script>

<style scoped>
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.actions {
  display: flex;
  justify-content: center;
}

.actions-button {
  margin-left: 10px;
  margin-right: 10px;
}

.section-title {
  font-family: sans-serif;
  margin-top: 20px;
  padding-top: 12px;
  padding-left: 20px;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  color: #4a4a4a;
}

.table-content {
  margin-top: 24px;
  width: 100%;
  box-shadow: 0px 1px 22px -12px #607d8b;
  background-color: #fff;
  padding: 10px;
  position: relative;
  border: 1px solid #dedede;
  border-radius: 3px;
  min-height: 500px;
  margin-bottom: 40px;
}

</style>
