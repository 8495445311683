/* eslint-disable */
export default class Transaction {

    operation: number;
    product_id: number | null;
    supplier_id: number | null;

    constructor(
        operation = 1,
        product_id = null,
        supplier_id = null
    )
    {
        this.operation = operation,
        this.product_id = product_id
        this.supplier_id = supplier_id
    }

}
