import Supplier from "@/models/supplier";
import http from "@/services/http-common";

class SupplierService {
  list() {
    return http.get("/suppliers")
  }

  details(id: number) {
    return http.get(`/suppliers/${id}`)
  }

  create(supplier: Supplier) {
    return http.post("/suppliers", supplier)
  }

  update(supplier: Supplier) {
    return http.put(`/suppliers/${supplier.id}`, supplier)
  }

  remove(id: number) {
    return http.delete(`/suppliers/${id}`)
  }
}

export default new SupplierService();
