import Credentials from "@/models/credentials";
import http from "@/services/http-common";

class SessionService {

    signUp(credentials: Credentials) {
        return http.post("/signup", credentials);
    }

    logIn(user: object) {
        console.log(user)
        return http.post('/login', { user } )
    }

    logOut() {
        return http.delete('/logout');
    }
}

export default new SessionService();
