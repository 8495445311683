import store from "@/store/index";
import router from "@/router/index";
import axios from "axios";

const http = axios.create({
  //baseURL: "https://desapegos.app/api"
  baseURL: "https://api.desapegos.app",
  //baseURL: "http://192.168.1.6:3000",
  //baseURL: "http://191.252.200.226/api",
  headers: {
    "Content-type": "application/json"
  }
});


http.interceptors.request.use(config => {
  //console.log("Request Interceptor", config);
  config.headers.common['Authorization'] = localStorage.getItem('token');
  return config;
});


http.interceptors.response.use(res => {
  //console.log("Response Interceptor", res);
    if (res.config.url === "/login")
  {
    //console.log("Login Efetuado")
  }
  return res;
}, error => {
  console.log(error.response)
  if (error.response.status === 401) {
    console.log("ERRO NA SUA SESSÃO")
    store.dispatch('session/clear', {})
    router.push('/401')
  }
});



export default http;

// when trying to access a protected resource with a fake token or invalid session,
// then forces the user to logout and redirects to home page.
// --------------------------------------------------------------------------------
//     axios.interceptors.response.use(
//         response => response, // simply return the response
//         error => {
//           if (error.response.status === 401) { // if we catch a 401 error
//             this.$store.dispatch('session/logout') // force a log out
//           }
//           return Promise.reject(error) // reject the Promise, with the error as the reason
//         }
