<template>
  <div class="container mt-6">
    <h1 class="title has-text-danger">ACESSO NÃO AUTORIZADO</h1>
    <p class="subtitle has-text-grey">a sua sessão expirou. por favor, faça o login novamente para continuar.</p>
  </div>
</template>

<script>
export default {
name: "Error401Page"
}
</script>

<style scoped>

</style>