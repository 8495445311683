<template>
  <div class="container mt-6">
    <h1 class="title has-text-danger">ACESSO NÃO AUTORIZADO</h1>
    <p class="subtitle has-text-grey">você não tem possui privilégios suficientes para essa operação.</p>
  </div>
</template>

<script>
export default {
name: "Error403Page"
}
</script>

<style scoped>

</style>