/* eslint-disable */

import SupplierService from "@/services/SupplierService";
import supplier from "@/models/supplier";

// ACTIONS, MUTATIONS AND GETTERS ARE
// ALWAYS REGISTERED UNDER THE GLOBAL NAMESPACE
// ... Using namespaced, prevents this behaviour.
export const namespaced = true;

export const state = {
    suppliers: [],
    supplier: {},
    total: 0
};

export const mutations = {
    SET_SUPPLIERS(state: any, supp: supplier[]) {

        supp.map(sup => {
            sup.searchField = sup.instagramUsername
                ? `${sup.name} ( ig @${sup.instagramUsername} )` //- [Cód. ${sup.id}]
                : `${sup.name}`; //  - [Cód. ${sup.id}]
        });

        state.suppliers = supp;
    },
    SET_TOTAL_SUPPLIERS(state: any, total: number) {
        state.total = total;
    },
    SET_SUPPLIER(state: any, supp: supplier) {
        state.supplier = supp;
    },
    ADD_SUPPLIER(state: any, supp: supplier) {
        supp.searchField = supp.instagramUsername
            ? `${supp.name} ( ig @${supp.instagramUsername} )` //- [Cód. ${sup.id}]
            : `${supp.name}`; //  - [Cód. ${sup.id}]
        state.suppliers.push(supp);
    },
    REMOVE_SUPPLIER(state: any, id: number) {
        state.suppliers = state.suppliers.filter((p: { id: number; }) => p.id != id);
    },
    UPDATE_SUPPLIER(state: any, id: number) {
        // @ts-ignore
        state.suppliers = state.suppliers.map(s => s.id !== state.supplier.id ? s : state.supplier);
    },
};



export const actions = {

    // @ts-ignore
    createSupplier({ commit, rootState, dispatch }, supplier) {
        SupplierService.create(supplier)
            .then( res => {
                commit("ADD_SUPPLIER", supplier);
                const notification = {
                    type: "success",
                    message: "Your supplier has been created!"
                };
                //dispatch("notification/add", notification, { root: true });
            })
            .catch(err => {
                const notification = {
                    type: "error",
                    message: "there was a problem creating the supplier: " + err.message
                };
                //dispatch("notification/add", notification, { root: true });
                throw err;
            });
    },

    // @ts-ignore
    fetchSuppliers({ commit, dispatch }, { perPage, page }) {
        SupplierService.list()
            .then(res => {
                commit("SET_SUPPLIERS", res.data);
                commit("SET_TOTAL_SUPPLIERS", res.headers["x-total-count"]);
            })
            .catch(err => {
                //console.log(err.response);
                const notification = {
                    type: "error",
                    message: "there was a problem fetching suppliers: " + err.message
                };
                //dispatch("notification/add", notification, { root: true });
            });
    },

    // @ts-ignore
    fetchSupplier({ commit, getters, dispatch }, id) {
        const supplier = getters.getSupplierById(id);

        if (supplier) {
            commit("SET_SUPPLIER", supplier);
        } else {
            SupplierService.details(id)
                .then(res => {
                    commit("SET_SUPPLIER", res.data);
                })
                .catch(err => {
                    const notification = {
                        type: "error",
                        message: "there was a problem fetching supplier: " + err.message
                    };
                    //dispatch("notification/add", notification, { root: true });
                });
        }
    },

    // @ts-ignore
    /*
    updateSupplier({ commit, dispatch }, id: number) {
        SupplierService.details(id)
            .then(res => {
                alert('update supplier'),
                commit("UPDATE_SUPPLIER", id, supplier);
            })
            .catch(err => {
                //console.log(err.response);
                const notification = {
                    type: "error",
                    message: "there was a problem fetching suppliers: " + err.message
                };
                //dispatch("notification/add", notification, { root: true });
            });
    },
     */

    // @ts-ignore
    removeSupplier({ commit, getters, dispatch }, id) {
        const supplier = getters.getSupplierById(id);

        if (supplier) {
            commit("REMOVE_SUPPLIER", supplier);
        } else {
            SupplierService.details(id)
                .then(res => {
                    commit("REMOVE_SUPPLIER", res.data.id);
                })
                .catch(err => {
                    const notification = {
                        type: "error",
                        message: "there was a problem fetching product: " + err.message
                    };
                    //dispatch("notification/add", notification, { root: true });
                });
        }
    },

    // @ts-ignore
    updateSupplier({ commit, getters, dispatch }, id) {
        state.supplier = getters.getSupplierById(id);
        console.log(state.supplier);
        SupplierService.details(id)
            .then(res => {
                // @ts-ignore
                state.supplier.accountBalance = res.data.accountBalance
                console.log(state.supplier);
            })
            .catch(err => {
                const notification = {
                    type: "error",
                    message: "there was a problem fetching product: " + err.message
                };
                //dispatch("notification/add", notification, { root: true });
            });

        if (state.supplier) {
            commit("UPDATE_SUPPLIER", id);
            console.log(state.supplier);
        } else {
            dispatch("supplier/fetchSuppliers")
        }
        console.log(state.supplier);

    },
};

export const getters = {
    getSupplierById: (state: { suppliers: any[]; }) => (id: number) => {
        return state.suppliers.find(supplier => supplier.id === id);
    },
};
