<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title" v-if="editMode">ALTERAÇÃO DE FORNECEDOR</p>
        <p class="modal-card-title" v-if="!editMode">CADASTRO DE FORNECEDOR</p>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <span class="column">

            <ValidationProvider
                name="Nome"
                rules="required"
                v-slot="{ errors, valid }"
            >
              <b-field label="Nome *"
                       :type="{ 'is-danger': errors[0], 'is-success': valid }"
                       :message="errors">
                <b-input
                  v-model="supplier.name"
                  placeholder="Nome..."
                  icon="female"
                  required

                >
                </b-input>
              </b-field>
            </ValidationProvider>

          </span>
        </div>
        <div class="columns">
          <span class="column is-half">
            <b-field label="Telefone...">
              <b-input
                v-model="supplier.phoneNumber"
                :controls="false"
                placeholder="(47) 99999-9999"
                icon="phone"
              >
              </b-input>
            </b-field>
          </span>
          <span class="column is-half">
            <b-field label="Instagram">
              <b-input
                v-model="supplier.instagramUsername"
                placeholder="@mamãe"
                icon-pack="fab"
                icon="instagram"
              >
              </b-input>
            </b-field>
          </span>
        </div>
        <div class="columns">
          <span class="column">
            <b-field label="Email">
              <b-input
                v-model="supplier.email"
                type="email"
                placeholder="mãe@email.com"
                icon="envelope"
              >
              </b-input>
            </b-field>
          </span>
        </div>

        <div class="columns">
          <span class="column is-half"> </span>
        </div>

        <!-- <b-checkbox>Manter selecionada após a inclusão</b-checkbox> -->
      </section>

      <footer class="modal-card-foot">
        <span class="modal-left">
          <b-button
            class="button is-dark"
            type="button"
            outlined
            @click="$emit('close')"
            >CANCELAR</b-button
          >
                    <b-tooltip :active="supplier.accountBalance > 0" :label="supplier.accountBalance > 0 ? 'necessário fazer acerto antes' : null ">
                      <b-button
                        v-if="editMode"
                        class="button is-danger"
                        type="button"
                        outlined
                        @click="archive()"
                        :disabled="supplier.accountBalance > 0"
                        :loading="sending"
                    >

        REMOVER</b-button
                    ></b-tooltip>
          <b-button
              v-if="!editMode"
            class="button is-info"
            type="button"
            outlined
            @click="reset()"
            >REINICIAR</b-button
          >
        </span>
        <span class="modal-right">
          <b-button class="button is-success" :loading="sending" @click="handleSubmit(submit)"
            >SALVAR</b-button
          >
        </span>
      </footer>
    </div>
    </ValidationObserver>
  </div>
</template>

<script>
import Supplier from "@/models/supplier";
import SupplierService from "@/services/SupplierService.ts";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  name: "ModalAddSupplier",
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: {
    supplier: {
      type: Object
    }
  },
  computed: {
    editMode: function() {
      return this.supplier.id >= 1;
    },
  },
  data() {
    return {
      sending: false,
    };
  },
  methods: {
    reset: function() {
      this.supplier = new Supplier();
      this.$refs.observer.reset();
    },
    submit: function() {
      this.sending = true;
      if (!this.editMode) {
        SupplierService.create(this.supplier)
            .then(response => {
              this.$store.commit("supplier/ADD_SUPPLIER", response.data);
              const notif = this.$buefy.notification.open({
                duration: 2500,
                message: `Fornecedor <strong><b>${response.data.name}</b></strong> adicionado com sucesso!`,
                type: "is-success",
                hasIcon: true
              });
              this.sending = false;
              this.$emit("close");
            })
            .catch(e => {
              if (e.response) {
                console.log(e.response.data); // => the response payload

                let errorMessage;
                const errors = Object.keys(e.response.data);
                console.log(errors);
                const notif = this.$buefy.notification.open({
                  duration: 2500,
                  message: `Não foi possível adicionar o fornecedor. <br/>O servidor retornou erro no campo: <br/>${errors.toString()}`,
                  type: "is-danger",
                  hasIcon: true
                });
              } else {
                const notif = this.$buefy.notification.open({
                  duration: 2500,
                  message: `Não foi possível adicionar o fornecedor.`,
                  type: "is-danger",
                  hasIcon: true
                });
              }

              this.sending = false;
            });
      }
      else {
        SupplierService.update(this.supplier)
            .then(response => {
              this.$store.dispatch("supplier/updateSupplier", this.supplierId);
              const notif = this.$buefy.notification.open({
                duration: 2500,
                message: `Fornecedor <strong><b>${response.data.name}</b></strong> alterado com sucesso!`,
                type: "is-success",
                hasIcon: true
              });
              this.sending = false;
              this.$emit("close");
            })
            .catch(e => {
              if (e.response) {
                console.log(e.response.data); // => the response payload
                let errorMessage;
                const errors = Object.keys(e.response.data);
                console.log(errors);
                const notif = this.$buefy.notification.open({
                  duration: 2500,
                  message: `Não foi possível alterar o fornecedor. <br/>O servidor retornou erro no campo: <br/>${errors.toString()}`,
                  type: "is-danger",
                  hasIcon: true
                });
              } else {
                const notif = this.$buefy.notification.open({
                  duration: 2500,
                  message: `Não foi possível alterar o fornecedor.`,
                  type: "is-danger",
                  hasIcon: true
                });
              }
              this.sending = false;
            });
      }
    },
    archive: function () {
      this.sending = true;
      SupplierService.remove(this.supplier.id)
          .then(response => {
            const notif = this.$buefy.notification.open({
              duration: 2500,
              message: `Fornecedor <strong><b>${response.data.name}</b></strong> removido com sucesso!`,
              type: "is-success",
              hasIcon: true
            });
            this.sending = false;
            this.$emit("close");
            this.$store.commit("supplier/REMOVE_SUPPLIER", this.supplier.id);
          })
          .catch(e => {
              const notif = this.$buefy.notification.open({
                duration: 2500,
                message: `Não foi possível remover o fornecedor.`,
                type: "is-danger",
                hasIcon: true
              });
            this.sending = false;
          });
    }
  }
};
</script>

<style scoped>
.modal-card-foot {
  display: flex;
  justify-content: space-between;
}
</style>
