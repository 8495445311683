<template>
  <div class="page">
     <!-- SEARCH BAR -->
    <section class="top-bar">
      <div class=" is-flex is-flex-direction-row is-align-items-flex-center">
          <b-field>
            <b-input
                placeholder="Procurar..."
                type="search"
                icon="search"
                icon-clickable
                v-model="filtro"
                clearable
            >
            </b-input>
          </b-field>
          <p class="middle-text"> ou </p>
            <b-button @click="openModal" type="is-primary" outlined
            >CADASTRAR MAMÃE...</b-button
            >
      </div>
      <p class="is-size-7 has-text-centered has-text-danger">Você pode procurar por nome ou pelo usuário do instagram</p>
    </section>


    <!-- SUPPLIER TABLES -->
    <b-table
      :data="isEmpty ? [] : filteredSupplierArray"
      :mobile-cards="true"
      :paginated="true"
      :per-page="perPage"
      :current-page="currentPage"
      :pagination-simple="false"
      pagination-position="bottom"
      default-sort-direction="desc"
      icon-pack="fa"
      default-sort="created_at"
      aria-next-label="Próxima página"
      aria-previous-label="Página anterior"
      aria-page-label="Página"
      aria-current-label="Página atual"
      :checked-rows.sync="checkedRows"
      :header-checkable="false"
      checkbox-position="right"
    >
        <b-table-column
          field="id"
          label="ID"
          sortable
          centered
          numeric
          v-slot="props"
          :visible="false"
        >
          {{ props.row.id }}
        </b-table-column>


      <b-table-column field="name" label="Nome" sortable centered v-slot="props">
        <span class="has-text-primary-dark has-text-weight-semibold">{{
          props.row.name
        }}</span>
      </b-table-column>

      <b-table-column field="instagramUsername" label="Instagram" sortable centered v-slot="props">
        <span class="">{{ props.row.instagramUsername }}</span>
      </b-table-column>

      <b-table-column field="phoneNumber" label="Telefone" centered v-slot="props">
        <span class="">
          {{ props.row.phoneNumber }}
        </span>
      </b-table-column>

      <b-table-column field="accountBalance" label="Balanço" sortable centered v-slot="props">
        <span class="has-text-weight-semibold has-text-info">
          R$ {{ props.row.accountBalance | formatValue }}
        </span>
      </b-table-column>

      <b-table-column label="Ações" centered v-slot="props" width="100px">
        <span class="actions" v-if="props.row.shop_admin != true">
          <b-button class="is-info actions-button is-small" @click="edit(props.row)" outlined> EDITAR </b-button>
          <b-button class="is-success actions-button is-small" @click="paySupplier(props.row.id)" outlined> ACERTO </b-button>
        </span>
        <p v-else> -- </p>
      </b-table-column>


    </b-table>
  </div>
</template>

<script>
import Supplier from "@/models/supplier";
import SupplierService from "@/services/SupplierService";
import ModalAddSupplier from "@/components/modals/ModalAddSupplier";
import ModalConfirmSupplierPayment from "@/components/modals/ModalConfirmSupplierPayment";

export default {
  name: "Moms",
  data() {
    return {
      isComponentModalActive: false,
      isEmpty: false,
      isLoading: false,
      currentPage: 1,
      perPage: 25,
      checkedRows: [],
      filtro: ''
    };
  },
  created() {
    if(!this.$store.state.session.loggedIn){
      this.$router.push('/')
    }
    else {
      this.$store.dispatch("supplier/fetchSuppliers", {
        perPage: 100,
        page: 1
      });
    }
  },
  methods: {
    searchIconClick: function (){
      console.log('searching...');
    },
    openModal: function() {
      this.$buefy.modal.open({
        parent: this,
        component: ModalAddSupplier,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        canCancel: false,
        props: {
          supplier: {}
        },
      });
    },
    edit: function(supplier) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalAddSupplier,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        canCancel: false,
        props: {
          supplier: supplier
        }
      });
    },
    paySupplier: function(id) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalConfirmSupplierPayment,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        canCancel: false,
        props: {
          supplierId: id
        },
      });
    }
  },
  filters: {
    formatValue: function (value) {

      return value.toFixed(2)

    }
  },
  computed: {
    suppliers() {
      return this.$store.state.supplier.suppliers;
    },
    filteredDataArray() {
      return this.suppliers.map(sup => {
        return sup.name + "( @" + sup.instagramUsername + " )";
      });
    },
    filteredSupplierArray: function () {
      if (this.filtro) {
        const exp = new RegExp(this.filtro.trim(), 'i');
        return this.suppliers.filter(sup => exp.test(sup.name) || exp.test(sup.instagramUsername));
      } else {
        return this.suppliers;
      }
    }
  },
}
</script>

<style scoped>

.page {
  margin-bottom: 35px;
  padding: 24px 24px;
}

p {
}

.actions {
  display: flex;
  justify-content: center;
}

.actions-button {
  margin-left: 10px;
  margin-right: 10px;
}

.middle-text {
  padding: 0 10px;
  margin: 0;
  padding-top: 7.5px;
}

.top-bar {
  margin-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

</style>
