<template>
  <div class="container mt-6">
    <h1 class="title has-text-danger">PÁGINA NÃO ENCONTRADA</h1>
    <p class="subtitle has-text-grey">tem certeza que era isso que você procurava?</p>
  </div>
</template>

<script>
export default {
name: "Error404Page"
}
</script>

<style scoped>

</style>