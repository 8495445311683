<template>
  <div class="page">
    <!-- INFOS PEÇA -->
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <section class="part-infos container">
        <span class="columns">
          <span class="column is-8">
            <span class="columns">
              <b-field class="column is-3" label="Identificação">
                <b-input
                  v-model="product.tag"
                  placeholder="A tag será gerada na criação..."
                  icon-pack="fas"
                  icon="tag"
                  disabled
                >
                </b-input>
              </b-field>

              <ValidationProvider
                name="Nome"
                class="column auto"
                rules="required"
                v-slot="{ errors, valid }"
              >
                <b-field
                  label="Nome da Peça"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-input
                    v-model="product.name"
                    placeholder="Peça ..."
                    icon-pack="fas"
                    icon="info"
                  >
                  </b-input>
                </b-field>
              </ValidationProvider>

              <ValidationProvider
                name="Categoria"
                class="column is-4"
                rules="required"
                v-slot="{ errors, valid }"
              >
                <b-field
                  label="Categoria"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-select
                    placeholder="Selecione..."
                    expanded
                    icon-pack="fas"
                    icon="cubes"
                    required
                    v-model="product.category_id"
                  >
                    <option
                      v-for="option in categories"
                      :value="option.id"
                      :key="option.id"
                    >
                      {{ option.name }}
                    </option>
                  </b-select>
                </b-field>
              </ValidationProvider>
            </span>

            <span class="columns">
              <b-field label="Descrição / Observações" class="column auto">
                <b-input
                  maxlength="350"
                  type="textarea"
                  v-model="product.description"
                ></b-input>
              </b-field>
            </span>
          </span>

          <span class="column">
            <b-field label="Deseja adicionar uma imagem?">
              <b-upload v-model="file" drag-drop @input="upload">
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                    </p>
                    <p>
                      Clique aqui para adicionar uma imagem. <br />
                      Ou arraste-a para dentro deste quadrado
                    </p>
                  </div>
                </section>
              </b-upload>
            </b-field>
            <div class="tags">
               <span v-for="(file, index) in dropFiles"
                     :key="index"
                     class="tag is-primary" >
                {{file.name}}
                <button class="delete is-small"
                        type="button"
                        @click="deleteFile(index)">
                </button>
            </span>
            </div>
          </span>
        </span>

        <span class="columns"> </span>
        <span class="columns">
          <ValidationProvider
            name="Gênero"
            class="column is-3"
            rules="required"
            v-slot="{ errors, valid }"
          >
            <b-field
              label="Gênero"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                v-model="product.gender"
                placeholder="Selecione..."
                expanded
                icon-pack="fas"
                icon="transgender"
                required
              >
                <option :value="0">Unisex</option>
                <option :value="1">Masculino</option>
                <option :value="2">Feminino</option>
              </b-select>
            </b-field>
          </ValidationProvider>

          <ValidationProvider
            name="Tamanho"
            class="column is-3"
            rules="required"
            v-slot="{ errors, valid }"
          >
            <b-field
              label="Tamanho"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                placeholder="Selecione..."
                expanded
                icon-pack="fas"
                icon="arrows-alt-h"
                required
                v-model="product.size_id"
              >
                <option
                  v-for="option in sizes"
                  :value="option.id"
                  :key="option.id"
                >
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>

          <ValidationProvider
            name="Preço de Compra"
            class="column is-3"
            vid="min"
            rules="required|min_value:0"
            v-slot="{ errors, valid }"
          >
            <b-field
              label="Preço de Compra"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-numberinput
                min="0"
                v-model="product.buyPrice"
                :controls="false"
                placeholder="R$"
                required
                icon-pack="fas"
                icon="dollar-sign"
              ></b-numberinput>
            </b-field>
          </ValidationProvider>

          <ValidationProvider
            name="Preço de Venda"
            vid="max"
            class="column is-3"
            rules="required|min_value:0"
            v-slot="{ errors, valid }"
          >
            <b-field
              label="Preço de Venda"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-numberinput
                min="0"
                v-model="product.sellPrice"
                :controls="false"
                placeholder="R$"
                required
                icon-pack="fas"
                icon="dollar-sign"
              ></b-numberinput>
            </b-field>
          </ValidationProvider>
        </span>
        <span class="columns">
          <ValidationProvider class="column auto" vid="consigned">
            <b-field>
              <b-checkbox :value="consigned" @input="toggleConsigned"
                >Peça em Consignação?</b-checkbox
              >
            </b-field>
          </ValidationProvider>
        </span>
        <span class="columns">
          <ValidationProvider
            name="Fornecedora"
            class="column is-5"
            rules="required"
            v-slot="{ errors, valid }"
          >
            <b-field
              label="Fornecedora"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-autocomplete
                v-model="supplierSelection"
                :data="filteredSupplierArray"
                field="searchField"
                placeholder="Procure pelo nome, sobrenome, instagram..."
                :clearable="consigned"
                icon-pack="fas"
                icon="female"
                :disabled="!consigned"
                @select="option => (selected = option)"
              >
                <template slot="empty">Nenhum resultado encontrado</template>
              </b-autocomplete>
            </b-field>
          </ValidationProvider>

          <ValidationProvider
            name="Valor Pago"
            rules="required_if:consigned|min_value:0|between:0,@min"
            class="column is-3"
            v-slot="{ errors, valid }"
          >
            <b-field
              label="Valor Pago"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-numberinput
                v-model="product.paidValue"
                min="0"
                :controls="false"
                placeholder="Pago em Consignação"
                icon-pack="fas"
                icon="dollar-sign"
                :disabled="!consigned"
              ></b-numberinput>
            </b-field>
          </ValidationProvider>
        </span>
      </section>

      <!-- ACTIONS BAR PEÇA -->
      <section class="container">
        <div class="buttons action-bar">
          <span>
            <b-button type="is-info" outlined @click="reset"
              >REINICIAR CAMPOS</b-button
            >
            <b-button @click="openModal" type="is-primary" outlined
              >CADASTRAR MAMÃE...</b-button
            >
          </span>
          <span class="right">
            <b-button
              type="is-success"
              @click="handleSubmit(submit)"
              :loading="sending"
            >
              ADICIONAR PEÇA</b-button
            >
          </span>
        </div>
      </section>
    </ValidationObserver>
  </div>
</template>

<script>
import ModalAddSupplier from "@/components/modals/ModalAddSupplier";
import Product from "@/models/product";
import http from "@/services/http-common";
import Supplier from "@/models/supplier";
import ModalConfirmAddition from "@/components/modals/ModalConfirmAddition";
import TransactionService from "@/services/TransactionService";
import transaction from "@/models/transaction";

import { mapState, mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    /* eslint-disable  */
    return {
      sending: false,
      selected: null,
      dropFiles: [],
      showAddMom: false,
      name: '',
      sellerMomId: 0,
      product: {},
      file: {},
      presigned: [],
      supplierSelection: null,
      consigned: false
    };
  },
  created() {
    if(!this.$store.state.session.loggedIn){
      this.$router.push('/')
    }
    else {
      this.$store.dispatch("itemProps/fetch", {
        perPage: 100,
        page: this.currentPage
      });
    }

    this.selected = this.suppliers[0];
    this.supplierSelection = this.suppliers[0].searchField

    this.product = new Product();

  },
  computed: {
    filteredSupplierArray: function () {
      if (this.supplierSelection) {
        const exp = new RegExp(this.supplierSelection.trim(), 'i');
        return this.suppliers.filter(sup => exp.test(sup.name) || exp.test(sup.instagramUsername));
      } else {
        return this.suppliers;
      }
    },
    ...mapState({
      categories: state => state.itemProps.categories,
      sizes: state => state.itemProps.sizes,
      suppliers: state => state.supplier.suppliers,
    })
  },
  filters: {
    formatKeyValue: function (data) {
      let retStg = '';
      const keys = Object.keys(data)
      const values = Object.values(data)
      return keys.toString()
    }
  },
  methods: {
    submit: function() {
      this.sending = true
      if (this.presigned !== []) {
        this.product.photo_data = this.presigned;
      }
      if (this.selected == null)
      {
        const notif = this.$buefy.notification.open({
          duration: 5000,
          message: 'É necessário identificar o vendedor!' + '\n' +
                    `Verifique o campo <b><i>Fornecedora</i></b> ou desmarque o campo <b><i>Peça em consignado?</i></b>`,
          type: 'is-danger',
          hasIcon: true
        })
        this.sending = false
      }
      else
      {
        this.product.supplier_id = this.selected.id;
        http.post("/products", this.product)
            .then(result => {
              this.product = result.data;
              this.confirmAddition(this.product)
              let trans
              if (this.consigned) {
                trans = new transaction(3, this.product.id, this.product.supplier_id)
              } else {
                trans = new transaction(1, this.product.id, this.product.supplier_id)
              }
              TransactionService.create(trans)
              this.$store.commit("product/ADD_PRODUCT", this.product)
              this.sending = false
            })
            .catch(er => {
              console.log(er.response)
              if (er.response.status === 422) {
                const keys = Object.keys(er.response.data)
                const errorMessage = 'Não foi possível adicionar o produto! Verifique os campos obrigatórios e o valores informados. \n' + '\n' + keys.toString()
                const notif2 = this.$buefy.notification.open({
                  duration: 15000,
                  message: errorMessage,
                  type: 'is-danger',
                  hasIcon: true
                })
                this.sending = false
              }
              if (er.response.status === 401) {
                const notif2 = this.$buefy.notification.open({
                  duration: 15000,
                  message: "Parece que você não está logado ou a sua sessão expirou. Realize o login novamente e se necessário clique em Sair ou atualize esta página.",
                  type: 'is-danger',
                  hasIcon: true
                })
                this.$router.push('/');
                this.$store.dispatch('session/clear')
                this.sending = false
              }
              else {
                const notif = this.$buefy.notification.open({
                  duration: 2500,
                  message: 'Não foi possível adicionar o produto! Erro desconhecido.' + er.response.message,
                  type: 'is-danger',
                  hasIcon: true
                })
                this.sending = false
              }


            });
      }
    },
    upload: function() {
      const formData = new FormData;
      formData.append('upload[file]', this.file);
      http.post('/uploads', formData)
          .then(resp => {
            this.presigned.push(resp.data)
            this.dropFiles.push(this.file)
          })
          .catch(function (errors) {
            console.log(errors)
          });
    },
    reset: function() {
      this.supplier = new Supplier()
      this.product = {}
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
    toDataUrl() {
        const vm = this.vm;
        const reader = new FileReader();
        reader.addEventListener("load", function () {
          const dataUrl = reader.result;
          vm.$emit('image', dataUrl);
          vm.$store.commit('image_preview/set', dataUrl)
        }.bind(vm), false);
        if (/\.(jpe?g|png|gif)$/i.test(vm.file.name)) {
          reader.readAsDataURL(vm.file);
        }
    },
    deleteFile: function(index) {
      this.dropFiles.splice(index, 1);
    },
    openModal: function() {
      this.$buefy.modal.open({
        parent: this,
        component: ModalAddSupplier,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        canCancel: false,
        props: {
          supplier: {}
        }
      });
    },
    confirmAddition: function(product) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalConfirmAddition,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        canCancel: false,
        trapFocus: true,
        props: {
          product: product
        },
        events: {
          'continue': () => {
            this.product = new Product();
            this.reset()
          }
        }
      });
    },
    toggleConsigned: function (){
      if (this.consigned) {
        this.selected = this.suppliers[0];
        this.supplierSelection = this.suppliers[0].searchField;
        this.product.paidValue = null
      } else {
        this.selected = null;
        this.supplierSelection = '';
      }
      this.consigned = !this.consigned;
    },
  }
};
</script>

<style scoped>

.page {
  padding: 24px 24px;
}

.part-infos {
  margin-top: 10px;
}

.buttons {
  margin-top: 16px;
}

.action-bar {
  display: flex;
  justify-content: space-between;
}
</style>
