<template>
  <div class="home">

    <!-- SEARCH BAR -->
    <section class="top-bar">
      <div class=" is-flex is-flex-direction-row is-align-items-flex-center">
        <b-field>
          <b-input
              placeholder="Procurar..."
              type="search"
              icon="search"
              icon-clickable
              v-model="filtro"
              clearable
          >
          </b-input>
        </b-field>
        <p class="middle-text"> ou </p>
        <router-link to="/include">
          <b-button type="is-primary" outlined>
            Adicionar Peça...
          </b-button>
        </router-link>
      </div>
      <p class="is-size-7 has-text-centered has-text-danger">Você pode procurar por nome ou tag do produto</p>
    </section>

    <!-- PAGE CONTENT:: PRODUCTS -->
    <section class="product-listing">
      <section class="mobile-view">
        <div v-for="product in filteredProductsArray" :key="product.id" >
          <div class="card pt-2 mb-4">
            <div class="card-image" v-if="product.widgets.length > 0">

              <b-carousel :indicator-inside="false" :arrow="true" :arrow-hover="false" :repeat="false" :autoplay="false">
                <b-carousel-item v-for="(widget, i) in product.widgets" :key="i">
                  <b-image class="image" :src="getImageAddress(widget)"></b-image>
                </b-carousel-item>
              </b-carousel>

            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-left">
                  <figure class="image is-48x48">
                    <p class="has-text-centered is-size-7">TAM.</p>
                    <span class=""></span>
                    <p
                        class="has-text-weight-bold is-size-3 has-text-centered"
                    >
                      {{ product.size.name }}
                    </p>
                  </figure>
                </div>
                <div class="media-content">
                  <p class="prod-name">
                    {{ product.name }}
                  </p>
                  <p class="prod-infos">
                    {{ product.category.name }}, {{ product.gender | convertGender }}
                  </p>
                  <p class="supp-infos" v-show="product.supplier.id > 1">
                    <i>vendido por <strong>{{ product.supplier.name }}</strong></i> <!-- @{{product.supplier.instagramUsername}} -->
                  </p>
                </div>
              </div>

              <div
                  class="content is-flex is-justify-content-space-between is-align-items-baseline"
              >
                <p class="subtitle is-6">
                  Tag:
                  <span class="is-size-6 has-text-weight-bold">
                        {{ product.tag }}</span
                  >
                </p>
                <b-tag class="is-primary is-light is-medium"
                ><span class="has-text-weight-bold">
                        R$ {{ product.sellPrice | formatValue }}
                      </span></b-tag
                >
                <br />
              </div>

              <div class="itemActionBar">
                <b-button class="is-primary is-outlined" @click="navigateToDetails(product.id)">DETALHES</b-button>
                <b-checkbox class="is-large" :value="isRowChecked(product.tag)" @input="checkProductCard(product.tag)"></b-checkbox>
              </div>
            </div>
          </div>
        </div>

      </section>
      <section class="desktop-view">
        <b-tabs expanded class="">

          <!-- PAGE CONTENT:: PRODUCTS TABLE -->
          <b-tab-item label="Tabela">

            <b-table
              :data="isEmpty ? [] : filteredProductsArray"
              :loading="isLoading"
              :mobile-cards="true"
              :paginated="true"
              :per-page="perPage"
              :current-page="currentPage"
              :pagination-simple="false"
              pagination-position="both"
              default-sort-direction="desc"
              icon-pack="fa"
              default-sort="['created_at','desc']"
              aria-next-label="Próxima página"
              aria-previous-label="Página anterior"
              aria-page-label="Page"
              aria-current-label="Current page"
              :checked-rows.sync="checkedRows"
              checkable
              :header-checkable="false"
              checkbox-position="right"
            >
              <b-table-column
                field="tag"
                label="TAG"
                sortable
                centered
                v-slot="props"
              >
                <a class="has-text-primary-dark has-text-weight-semibold" @click="navigateToDetails(props.row.id)">
                      {{ props.row.tag }}
                </a>
              </b-table-column>

              <b-table-column
                  field="name"
                  label="Peça"
                  sortable
                  centered
                  v-slot="props"
              >
                {{ props.row.name }}
              </b-table-column>

              <b-table-column
                field="category"
                label="Categoria"
                sortable
                centered
                v-slot="props"
              >
                {{ props.row.category.name }}
              </b-table-column>

              <b-table-column
                field="gender"
                label="Gênero"
                sortable
                centered
                v-slot="props"
              >
                {{ props.row.gender | convertGender }}
              </b-table-column>

              <b-table-column
                field="size"
                label="Tamanho"
                sortable
                centered
                v-slot="props"
              >
                {{ props.row.size.name }}
              </b-table-column>

              <b-table-column
                field="result"
                label="Preço Venda"
                sortable
                centered
                v-slot="props"
              >
                <span class="has-text-primary"
                  >R$ {{ props.row.sellPrice | formatValue }}</span
                >
              </b-table-column>

              <b-table-column
                  field="created_at"
                  label="Data de Entrada"
                  sortable
                  v-slot="props"
                  :visible="false"
              >
                {{ props.row.created_at }}
              </b-table-column>

            </b-table>
          </b-tab-item>

          <!-- PAGE CONTENT:: PRODUCTS CARDS -->
          <b-tab-item label="Cartões">
            <div v-for="product in filteredProductsArray" :key="product.id" class="prod-cards">
              <div class="card pt-2 mb-4" v-if="product.widgets">
                <div class="card-image" v-if="product.widgets.length > 0">
                  <b-carousel :indicator-inside="false" :arrow="true" :arrow-hover="false" :repeat="false" :autoplay="false">
                    <b-carousel-item v-for="(widget, i) in product.widgets" :key="i">
                      <b-image class="image" :src="getImageAddress(widget)"></b-image>
                    </b-carousel-item>
                    <!--
                    <template #indicators="props">
                      <b-image class="al image" :src="getImageAddress(props)" :title="props.i"></b-image>
                    </template>
                    -->
                  </b-carousel>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-left">
                      <figure class="image is-48x48">
                        <p class="has-text-centered is-size-7">TAM.</p>
                        <span class=""></span>
                        <p
                          class="has-text-weight-bold is-size-3 has-text-centered"
                        >
                          {{ product.size.name }}
                        </p>
                      </figure>
                    </div>
                    <div class="media-content">
                      <p class="subtitle is-6">
                        Tag:
                        <span class="is-size-5 has-text-weight-bold">
                        {{ product.tag }}</span
                        >
                      </p>
                      <b-tag class="is-primary is-light is-medium"
                      ><span class="has-text-weight-bold">
                        R$ {{ product.sellPrice | formatValue }}
                      </span></b-tag
                      >
                      <br />

                    </div>
                  </div>

                  <div
                    class="content "
                  >
                    <p class="prod-name">
                      {{ product.name }}
                    </p>
                    <p class="prod-infos">
                      {{ product.category.name }}, {{ product.gender | convertGender }}
                    </p>
                    <p class="supp-infos" v-show="product.supplier.id > 1">
                      <i>vendido por <strong>{{ product.supplier.name }}</strong></i> <!-- @{{product.supplier.instagramUsername}} -->
                    </p>
                  </div>



                  <div class="itemActionBar">
                    <b-button class="is-primary is-outlined" @click="navigateToDetails(product.id)">DETALHES</b-button>
                    <b-checkbox class="is-large" :value="isRowChecked(product.tag)" @input="checkProductCard(product.tag)"></b-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>

        </b-tabs>
      </section>
    </section>

    <!-- ACTIONS FOOTER -->
    <section class="actionsBar">
      <b-field grouped group-multiline>
        <b-button
          class="button field is-info"
          @click="clearSelection()"
          :disabled="!checkedRows.length"
          outlined
        >
          <b-icon icon="times"></b-icon>
          <span>Limpar Seleção</span>
        </b-button>
        <b-button
          class="button field is-danger"
          @click="deletionModal()"
          :disabled="!checkedRows.length"
        >
          <b-icon icon="trash-alt"></b-icon>
          <span>Devolver Selecionados</span>
        </b-button>
        <b-button
          class="button field is-success"
          @click="salesModal()"
          :disabled="!checkedRows.length"
        >
          <b-icon icon="shopping-cart"></b-icon>
          <span>Vender Selecionados</span>
        </b-button>
      </b-field>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
//import { ipcRenderer } from 'electron';
//import { remote } from 'electron';
//import { ipcMain } from 'electron'
import { mapState, mapActions } from "vuex";

import ModalConfirmSale from "@/components/modals/ModalConfirmSale";
import ModalConfirmRemoval from "@/components/modals/ModalConfirmRemoval";



export default {
  name: "Home",
  components: {
    ModalConfirmSale
  },
  data() {
    return {
      isComponentModalActive: false,
      isEmpty: false,
      isLoading: false,
      currentPage: 1,
      perPage: 25,
      checkedRows: [],
      filtro: '',
      values: 1,
    };
  },
  created() {
    if(!this.$store.state.session.loggedIn){
      this.$router.push('/')
    }
    else {
      this.$store.dispatch("product/fetchProducts", {
        perPage: 25,
        page: this.currentPage
      });
    }
  },
  computed: {
    filteredProductsArray: function () {
      if (this.filtro) {
        const exp = new RegExp(this.filtro.trim(), 'i');
        return this.products.filter(prod => exp.test(prod.tag));
      } else {
        return this.products;
      }
    },
    products: function (){
      return this.$store.state.product.products;
    }
  },
  filters: {
    convertGender: function (value) {

      if (value === 0)
        return 'Unisex'
      if (value === 1)
        return 'Masculino'
      if (value === 2)
        return 'Feminino'
      else
        return ''
    },
    formatValue: function (value) {

      return value.toFixed(2)

    }
  },
  methods: {
    getImageAddress: function (widget) {
        return `https://api.desapegos.app/uploads/${widget.filename}`
        //return `https://images.desapegos.app/${widget.filename}`
        //return `http://192.168.1.6:3000/uploads/${widget.filename}`
        //return `http://191.252.200.226/api/uploads/?file=${product.photo_data.id}`
    },

    isRowChecked: function (tag) {
      if (this.checkedRows.find( x => x.tag == tag) != null )
        return true
      else
        return false
    },
    checkProductCard: function (tag) {
      if (this.checkedRows.find( x => x.tag == tag) == null )
        this.checkedRows.push(this.products.find(p => p.tag == tag));
      else
        this.checkedRows = this.checkedRows.filter (f => f.tag != tag);
    },
    salesModal() {
      this.$buefy.modal.open({
        parent: this,
        component: ModalConfirmSale,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        canCancel: false,
        props: {
          products: this.checkedRows
        },
        events: {
          'success': value => {
            this.clearSelection();
          }
        }
      });
    },
    deletionModal() {
      this.$buefy.modal.open({
        parent: this,
        component: ModalConfirmRemoval,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        canCancel: false,
        props: {
          products: this.checkedRows
        },
        events: {
          'success': value => {
            this.clearSelection();
          }
        }
      });
    },
    navigateToDetails(id) {
      this.$router.push(`/details/${id}`);
    },
    clearSelection() {
      this.checkedRows = [];
    },

  }
};
</script>

<style lang="scss" scoped>
.home {
  margin-bottom: 35px;
  padding: 24px 24px;
}

.searchBar {
}

.product-listing {
  padding-top: 20px;
}

.actionsBar {
  margin-top: 25px;
  margin-bottom: 35px;
}

.img-size {
  height: 256px;
  width: 256px;
}

.itemActionBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.actionsBar .button {
  margin-top: 15px;
}

.top-bar {
  padding-bottom: 15px;
  margin: 0 5px;
}

.middle-text {
  padding: 0 10px;
  padding-top: 7.5px;
}

.prod-name {
  font-weight: bold;
  font-size: 1.25rem;
}

.prod-infos {
  margin-bottom: 0px;
}

.supp-infos {
  font-size: 0.75rem;
  margin-top: 0px;
  padding-left: 5px;
}

.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
}

@media only screen and (min-width : 320px) and (max-width: 420px){
  .mobile-view {
    display: block;
  }
  .desktop-view {
    display: none;
  }
}

@media only screen and (min-width : 350px) {
  .desktop-view {
    display: block;
  }
  .mobile-view {
    display: none;
  }
}

</style>
