/* eslint-disable */

import ProductService from "@/services/ProductService";
import product from "@/models/product";
import http from "@/services/http-common";
import transaction from "@/models/transaction";
import TransactionService from "@/services/TransactionService";

// ACTIONS, MUTATIONS AND GETTERS ARE
// ALWAYS REGISTERED UNDER THE GLOBAL NAMESPACE
// ... Using namespaced, prevents this behaviour.
export const namespaced = true;

export const state = {
  products: [],
  product: {},
  total: 0
};

export const mutations = {
  SET_PRODUCTS(state: any, prods: product) {
    state.products = prods;
  },
  SET_TOTAL_PRODUCTS(state: any, total: number) {
    state.total = total;
  },
  SET_PRODUCT(state: any, prod: product) {
    state.product = prod;
  },
  ADD_PRODUCT(state: any, prod: product) {
    state.products.push(prod);
  },
  REMOVE_PRODUCT(state: any, id: number) {
    state.products = state.products.filter((p: { id: number; }) => p.id != id);
  },
  SELL_PRODUCT(state: any, id: number) {
    state.products = state.products.filter((p: { id: number; }) => p.id != id);
  },
};



export const actions = {

  // @ts-ignore
  createProduct({ commit, rootState, dispatch }, product) {

    ProductService.create(product)
      .then( res => {
        product = res.data;
        let trans
        if (product.supplier_id > 1) {
          trans = new transaction(3, product.id, product.supplier_id)
        } else {
          trans = new transaction(1, product.id, product.supplier_id)
        }
        TransactionService.create(trans)
        commit("ADD_PRODUCT", product);
        const notification = {
          type: "success",
          message: "Your product has been created!"
        };
        //dispatch("notification/add", notification, { root: true });
      })
      .catch(err => {
        const notification = {
          type: "error",
          message: "there was a problem creating the product: " + err.message
        };
        //dispatch("notification/add", notification, { root: true });
        throw err;
      });
  },

  // @ts-ignore
  fetchProducts({ commit, dispatch }, { perPage, page }) {
    ProductService.list()
      .then(res => {
        commit("SET_PRODUCTS", res.data);
        commit("SET_TOTAL_PRODUCTS", res.headers["x-total-count"]);
      })
      .catch(err => {
        //console.log(err.response);
        const notification = {
          type: "error",
          message: "there was a problem fetching products: " + err.message
        };
        //dispatch("notification/add", notification, { root: true });
      });
  },

  // @ts-ignore
  fetchProduct({ commit, getters, dispatch }, id) {
    const product = getters.getProductById(id);

    if (product) {
      commit("SET_PRODUCT", product);
    } else {
      ProductService.details(id)
        .then(res => {
          commit("SET_PRODUCT", res.data);
        })
        .catch(err => {
          const notification = {
            type: "error",
            message: "there was a problem fetching product: " + err.message
          };
          dispatch("notification/add", notification, { root: true });
        });
    }
  },

  // @ts-ignore
  removeProduct({ commit, getters, dispatch }, id) {
    const product = getters.getProductById(id);

    if (product) {
      commit("REMOVE_PRODUCT", id);
    } else {
      ProductService.details(id)
          .then(res => {
            commit("REMOVE_PRODUCT", res.data.id);
          })
          .catch(err => {
            const notification = {
              type: "error",
              message: "there was a problem fetching product: " + err.message
            };
            //dispatch("notification/add", notification, { root: true });
          });
    }
  },

  // @ts-ignore
  sellProduct({ commit, getters, dispatch }, id) {
    const product = getters.getProductById(id);

    if (product) {
      commit("SELL_PRODUCT", id);
    } else {
      ProductService.details(id)
          .then(res => {
            commit("SELL_PRODUCT", res.data.id);
          })
          .catch(err => {
            const notification = {
              type: "error",
              message: "there was a problem fetching product: " + err.message
            };
            //dispatch("notification/add", notification, { root: true });
          });
    }
  }

};

export const getters = {
  getProductById: (state: { products: any[]; }) => (id: any) => {
    return state.products.find(product => product.id === id);
  }
};
