<template>
<div>
  <h1> ABOUT <strong>desapegos.app</strong></h1>
</div>
</template>

<script>
export default {
name: "AboutLandingPage"
}
</script>

<style scoped>

</style>