/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable */

import Vue from "vue";
import Buefy from "buefy";
import store from "./store/index";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import { extend } from 'vee-validate'
import { required, required_if, email, min, min_value, max_value } from 'vee-validate/dist/rules';



import VueApexCharts from "vue-apexcharts";
import VueLazyload from 'vue-lazyload'

import "./assets/css/app.scss";

Vue.use(VueLazyload)

// or with options
/*
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'dist/error.png',
  loading: 'dist/loading.gif',
  attempt: 1
})
*/


Vue.prototype.$http = axios;

import moment from 'moment'

Vue.prototype.moment = moment

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts)

Vue.use(Buefy, {
  defaultIconPack: "fas"
});

Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false;


extend('positive', value => {
  return value >= 0;
});

extend('min_value', {
  ...min_value,
  message: 'Valor inálido. O mínimo definido é {min}'
});
extend('max_value', max_value);
extend('required_if', required_if);
extend('required', {
  ...required,
  message: 'Este campo é obrigatório!'
});


extend('between', {
  params: ['min', 'max'],
  // @ts-ignore
  validate(value, {min, max: max }) {
    return value >= min && value <= max;
  },
  message: 'This field value must be between {min} and {max}'
});

new Vue({
  router,
  store,
  created () {
    // grab user data from local storage
    const userString = localStorage.getItem('user');
    console.log('running initializers');

    // check to see if there is indeed a user
    if (userString) {
      const user = JSON.parse(userString) // parse user data into JSON
      const token = localStorage.getItem('token');
      this.$store.dispatch("session/restore", { user: user, token: token } );
      this.$store.dispatch("supplier/fetchSuppliers", {
        perPage: 100
      });
      this.$router.push("/products");
    }
    else {
      this.$store.dispatch("session/clear")
    }
  },
  render: h => h(App)
}).$mount("#app");
