var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("CONFIRMAR VENDA")])]),_c('section',{staticClass:"modal-card-body"},[_c('br'),_c('p',{staticClass:"has-text-success"},[_vm._v("Opa, mais uma venda. Que maravilha!")]),_c('br'),_c('p',[_vm._v(" O valor total desta venda é de "),_c('span',{staticClass:"has-text-success has-text-weight-bold"},[_vm._v(" R$ "+_vm._s(_vm._f("formatValue")(_vm.productsTotalValue)))]),_vm._v(", para os produtos de tag: ")]),_c('p',{staticClass:"product-listing"},_vm._l((_vm.products),function(product){return _c('span',{key:product.id,staticClass:"has-text-weight-semibold has-text-danger"},[_vm._v(_vm._s(product.tag)+", ")])}),0),_c('hr'),_c('ValidationProvider',{attrs:{"name":"Desconto","rules":"min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Deseja conceder algum desconto?","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-numberinput',{attrs:{"type":"number","placeholder":"R$ 0,00","controls":false,"icon-pack":"fas","icon":"dollar-sign"},model:{value:(_vm.sale.discount),callback:function ($$v) {_vm.$set(_vm.sale, "discount", $$v)},expression:"sale.discount"}})],1)]}}],null,true)}),_c('b-field',{attrs:{"label":"Deseja identificar a compradora?"}},[_c('b-autocomplete',{attrs:{"data":_vm.filteredbuyerArray,"field":"searchField","placeholder":"Procure pelo nome, sobrenome, instagram...","clearable":"","icon-pack":"fas","icon":"female"},on:{"select":function (option) { return (_vm.buyer = option); }},model:{value:(_vm.buyerSelection),callback:function ($$v) {_vm.buyerSelection=$$v},expression:"buyerSelection"}},[_c('template',{slot:"empty"},[_vm._v("Nenhum resultado encontrado")])],2)],1),_c('ValidationProvider',{attrs:{"name":"Frete","rules":"min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Qual é o valor do frete?","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-numberinput',{attrs:{"type":"number","placeholder":"R$ 0,00","controls":false,"icon-pack":"fas","icon":"dollar-sign"},model:{value:(_vm.sale.freight),callback:function ($$v) {_vm.$set(_vm.sale, "freight", $$v)},expression:"sale.freight"}})],1)]}}],null,true)}),_c('hr'),_c('p',{staticClass:"has-text-weight-bold"},[_vm._v(" O valor total da venda é de "),_c('span',{staticClass:"has-text-success"},[_vm._v(" R$ "+_vm._s(_vm._f("formatValue")(_vm.saleTotalValue))+" ")])]),(false)?_c('p',[_vm._v(" Ao confirmar esta ação, os produtos que estão em consignação serão removidos e a diferença entre o será adicionado como débito com o fornecedor. ")]):_vm._e()],1),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{staticClass:"button is-dark",attrs:{"outlined":"","type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("CANCELAR")]),_c('b-button',{staticClass:"button is-success",attrs:{"loading":_vm.sending},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v("VENDER")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }