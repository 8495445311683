<template>
  <div id="app">

    <section class="site-nav">
      <RdnzNavbar />
    </section>

      <section class="site-content">
        <router-view />
      </section>


    <section class="site-footer">
      <rdnz-footer />
    </section>

  </div>
</template>

<!-- NÃO SCOPED, POIS CARREGA O ESTILO APP.SCSS PARA TODOS OS COMPONENTES. NECESSÁRIO MOVER ESSE IMPORT PARA O ARQUIVO DE CONFIG. -->
<style lang="scss" scoped>

.site-nav {
  width: 100%;
  height: 64px;
  border-bottom: 2px solid grey;
}

.site-content {
  overflow-y: scroll;
  margin: 0 auto;
 // padding: 24px 24px;  ##TODO: INCLUIR NAS PAGINAS O PADDING RETIRADO DAQUI
  height: calc(100vh - 98px);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.site-footer {
  margin: 0 auto;
  width: 100%;
}


</style>

<script>
import RdnzNavbar from "@/components/partials/RdnzNavbar";
import RdnzFooter from "@/components/partials/RdnzFooter";

export default {
  components: {RdnzFooter, RdnzNavbar },
  data() {
    return {
      developmentWarning: false
    };
  },
  computed: {
    loggedIn: function () {
      return this.$store.state.session.loggedIn;
    },
  },
  methods: {
    closeAlert: function () {
      localStorage.setItem('devEnvAck', true)
    }
  },
  created() {
    this.developmentWarning = localStorage.getItem('devEnvAck')
  }

};
</script>
