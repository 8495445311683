var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('section',{staticClass:"part-infos container"},[_c('span',{staticClass:"columns"},[_c('span',{staticClass:"column is-8"},[_c('span',{staticClass:"columns"},[_c('b-field',{staticClass:"column is-3",attrs:{"label":"Identificação"}},[_c('b-input',{attrs:{"placeholder":"A tag será gerada na criação...","icon-pack":"fas","icon":"tag","disabled":""},model:{value:(_vm.product.tag),callback:function ($$v) {_vm.$set(_vm.product, "tag", $$v)},expression:"product.tag"}})],1),_c('ValidationProvider',{staticClass:"column auto",attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Nome da Peça","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"placeholder":"Peça ...","icon-pack":"fas","icon":"info"},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", $$v)},expression:"product.name"}})],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"column is-4",attrs:{"name":"Categoria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Categoria","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-select',{attrs:{"placeholder":"Selecione...","expanded":"","icon-pack":"fas","icon":"cubes","required":""},model:{value:(_vm.product.category_id),callback:function ($$v) {_vm.$set(_vm.product, "category_id", $$v)},expression:"product.category_id"}},_vm._l((_vm.categories),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)]}}],null,true)})],1),_c('span',{staticClass:"columns"},[_c('b-field',{staticClass:"column auto",attrs:{"label":"Descrição / Observações"}},[_c('b-input',{attrs:{"maxlength":"350","type":"textarea"},model:{value:(_vm.product.description),callback:function ($$v) {_vm.$set(_vm.product, "description", $$v)},expression:"product.description"}})],1)],1)]),_c('span',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Deseja adicionar uma imagem?"}},[_c('b-upload',{attrs:{"drag-drop":""},on:{"input":_vm.upload},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"upload","size":"is-large"}})],1),_c('p',[_vm._v(" Clique aqui para adicionar uma imagem. "),_c('br'),_vm._v(" Ou arraste-a para dentro deste quadrado ")])])])])],1),_c('div',{staticClass:"tags"},_vm._l((_vm.dropFiles),function(file,index){return _c('span',{key:index,staticClass:"tag is-primary"},[_vm._v(" "+_vm._s(file.name)+" "),_c('button',{staticClass:"delete is-small",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteFile(index)}}})])}),0)],1)]),_c('span',{staticClass:"columns"}),_c('span',{staticClass:"columns"},[_c('ValidationProvider',{staticClass:"column is-3",attrs:{"name":"Gênero","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Gênero","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-select',{attrs:{"placeholder":"Selecione...","expanded":"","icon-pack":"fas","icon":"transgender","required":""},model:{value:(_vm.product.gender),callback:function ($$v) {_vm.$set(_vm.product, "gender", $$v)},expression:"product.gender"}},[_c('option',{domProps:{"value":0}},[_vm._v("Unisex")]),_c('option',{domProps:{"value":1}},[_vm._v("Masculino")]),_c('option',{domProps:{"value":2}},[_vm._v("Feminino")])])],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"column is-3",attrs:{"name":"Tamanho","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Tamanho","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-select',{attrs:{"placeholder":"Selecione...","expanded":"","icon-pack":"fas","icon":"arrows-alt-h","required":""},model:{value:(_vm.product.size_id),callback:function ($$v) {_vm.$set(_vm.product, "size_id", $$v)},expression:"product.size_id"}},_vm._l((_vm.sizes),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"column is-3",attrs:{"name":"Preço de Compra","vid":"min","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Preço de Compra","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-numberinput',{attrs:{"min":"0","controls":false,"placeholder":"R$","required":"","icon-pack":"fas","icon":"dollar-sign"},model:{value:(_vm.product.buyPrice),callback:function ($$v) {_vm.$set(_vm.product, "buyPrice", $$v)},expression:"product.buyPrice"}})],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"column is-3",attrs:{"name":"Preço de Venda","vid":"max","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Preço de Venda","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-numberinput',{attrs:{"min":"0","controls":false,"placeholder":"R$","required":"","icon-pack":"fas","icon":"dollar-sign"},model:{value:(_vm.product.sellPrice),callback:function ($$v) {_vm.$set(_vm.product, "sellPrice", $$v)},expression:"product.sellPrice"}})],1)]}}],null,true)})],1),_c('span',{staticClass:"columns"},[_c('ValidationProvider',{staticClass:"column auto",attrs:{"vid":"consigned"}},[_c('b-field',[_c('b-checkbox',{attrs:{"value":_vm.consigned},on:{"input":_vm.toggleConsigned}},[_vm._v("Peça em Consignação?")])],1)],1)],1),_c('span',{staticClass:"columns"},[_c('ValidationProvider',{staticClass:"column is-5",attrs:{"name":"Fornecedora","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Fornecedora","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-autocomplete',{attrs:{"data":_vm.filteredSupplierArray,"field":"searchField","placeholder":"Procure pelo nome, sobrenome, instagram...","clearable":_vm.consigned,"icon-pack":"fas","icon":"female","disabled":!_vm.consigned},on:{"select":function (option) { return (_vm.selected = option); }},model:{value:(_vm.supplierSelection),callback:function ($$v) {_vm.supplierSelection=$$v},expression:"supplierSelection"}},[_c('template',{slot:"empty"},[_vm._v("Nenhum resultado encontrado")])],2)],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"column is-3",attrs:{"name":"Valor Pago","rules":"required_if:consigned|min_value:0|between:0,@min"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Valor Pago","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-numberinput',{attrs:{"min":"0","controls":false,"placeholder":"Pago em Consignação","icon-pack":"fas","icon":"dollar-sign","disabled":!_vm.consigned},model:{value:(_vm.product.paidValue),callback:function ($$v) {_vm.$set(_vm.product, "paidValue", $$v)},expression:"product.paidValue"}})],1)]}}],null,true)})],1)]),_c('section',{staticClass:"container"},[_c('div',{staticClass:"buttons action-bar"},[_c('span',[_c('b-button',{attrs:{"type":"is-info","outlined":""},on:{"click":_vm.reset}},[_vm._v("REINICIAR CAMPOS")]),_c('b-button',{attrs:{"type":"is-primary","outlined":""},on:{"click":_vm.openModal}},[_vm._v("CADASTRAR MAMÃE...")])],1),_c('span',{staticClass:"right"},[_c('b-button',{attrs:{"type":"is-success","loading":_vm.sending},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v(" ADICIONAR PEÇA")])],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }