<template>
  <div class="container mt-6">
    <h1 class="title has-text-danger">ERRO INTERNO DO SERVIDOR</h1>
    <p class="subtitle has-text-grey">deixa com a gente. infelizmente não há nada que você possa fazer :(</p>
  </div>
</template>

<script>
export default {
name: "Error500Page"
}
</script>

<style scoped>

</style>