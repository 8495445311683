import Product from "@/models/product";
import http from "@/services/http-common";

class ProductService {

  list() {
    return http.get("/products");
  }

  details(id: string) {
    return http.get(`/products/${id}`);
  }

  create(product: Product) {
    return http.post('/products', product );
  }

  delete(id: string) {
    return http.delete(`/products/${id}`);
  }
}

export default new ProductService();
