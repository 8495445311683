<template>
  <section>

    <div class="section-header">

      <h1 class="section-title">CATEGORIAS E TAMANHOS</h1>
      <b-select
        class="section-selector is-small"
        v-model="period"
        placeholder="Todo o Tempo"
      >
        <option value="month">Mensal</option>
        <option value="week">Semanal</option>
      </b-select>
    </div>

    <div class="sales-result-wrapper">
      <article class="sales-result-card">
        <apexchart
          type="pie"
          height="350"
          :options="categoryStatsOptions"
          :series="categoryStatsSeries"
        ></apexchart>
      </article>

      <article class="sales-result-card">
        <apexchart
          type="pie"
          height="350"
          :options="sizeStatsOptions"
          :series="sizeStatsSeries"
        ></apexchart>
      </article>

    </div>

  </section>
</template>

<script>
import ReportService from "@/services/ReportService";
import VueApexCharts from "apexcharts";

export default {
  name: "CategoriesAndSizes",
  data: function() {
    return {
      isEmpty: false,
      isLoading: false,
      period: null,
      categoryStatsOptions: {
        chart: {
          type: "pie",
          id: "category-stats"
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center"
        },
        plotOptions: {
          pie: {
            expandOnClick: false
          }
        },
        labels: [],
        dataLabels: {
          enabled: false,
          style: {
            fontSize: "10px",
            fontFamily: "Helvetica, Arial, sans-serif"
          }
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontolor: "#4C4C4C"
          }
        },
        title: {
          text: "POR CATEGORIA",
          align: "left",
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "12px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
            color: "#263238"
          }
        }
      },
      categoryStatsSeries: [],
      sizeStatsOptions: {
        chart: {
          type: "pie",
          id: "size-stats"
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center"
        },
        dataLabels: {
          enabled: false,
          style: {
            fontSize: "14px",
            fontFamily: "Helvetica, Arial, sans-serif",
            colors: ["#0E0E0E"]
          }
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontolor: "#4C4C4C"
          }
        },
        plotOptions: {
          pie: {
            expandOnClick: false
          }
        },
        labels: [],
        title: {
          text: "POR TAMANHO",
          align: "left",
          offsetX: 20,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "12px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
            color: "#263238"
          }
        }
      },
      sizeStatsSeries: []
    };
  },
  methods: {
    fetchData: function(queryFilter) {
      ReportService.statistics(queryFilter)
        .then(response => {
          //console.log(response.data);
          const _catLabels = Object.keys(response.data.categories);
          const _catValues = Object.values(response.data.categories);
          const _sizLabels = Object.keys(response.data.sizes);
          const _sizValues = Object.values(response.data.sizes);

          this.categoryStatsSeries = _catValues.slice(0, 5);
          this.sizeStatsSeries = _sizValues.slice(0, 5);

          VueApexCharts.exec("category-stats", "updateOptions", {
            labels: _catLabels.slice(0, 5)
          });
          VueApexCharts.exec("size-stats", "updateOptions", {
            labels: _sizLabels.slice(0, 5)
          });
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  watch: {
    period: function(val, oldVal) {
      this.fetchData({ period: val });
    }
  },
  mounted() {
    this.fetchData({});
  }
};
</script>

<style scoped>
.section-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.section-title {
  font-family: sans-serif;
  margin-top: 20px;
  padding-top: 12px;
  padding-left: 20px;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  color: #4a4a4a;
}

.sales-result-wrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.sales-result-card {
  box-shadow: 0px 1px 22px -12px #607d8b;
  background-color: #fff;
  padding: 20px 0;
  position: relative;
  border: 1px solid #dedede;
  border-radius: 3px;
  margin-bottom: 20px;
}
</style>
