import Transaction from "@/models/transaction";
import http from "@/services/http-common";

class TransactionService {

    list() {
        return http.get("/transactions");
    }

    details(id: string) {
        return http.get(`/transactions/${id}`)
    }

    create(transaction: Transaction) {
        return http.post("/transactions", transaction )
    }
}

export default new TransactionService();
