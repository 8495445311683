/* eslint-disable */

import CategoryService from "@/services/CategoryService";
import user from "@/models/user";
import Credentials from "@/models/credentials";
import axios from 'axios';
import category from "@/models/category";
import size from "@/models/size";
import SizeService from "@/services/SizeService";

// ACTIONS, MUTATIONS AND GETTERS ARE
// ALWAYS REGISTERED UNDER THE GLOBAL NAMESPACE
// ... Using namespaced, prevents this behaviour.
export const namespaced = true;

export const state = {
    loaded: false,
    categories: [],
    categoriesCount: 0,
    sizes: [],
    sizesCount: 0,
    genres: [],
    genresCount: 0
};

export const mutations = {
    SET_CATEGORIES(state: any, cats: category[]) {
        state.categories = cats;
    },
    SET_CATEGORIES_COUNT(state: any, total: number) {
        state.categoriesCount = total;
    },
    SET_SIZES(state: any, sizes: size[]) {
        state.sizes = sizes;
    },
    SET_SIZES_COUNT(state: any, total: number) {
        state.sizesCount = total;
    },
    RESET_PROPS(state: any) {
        state.loaded = false;
        state.categories = [];
        state.sizes = [];
    },
};

export const actions = {

    // @ts-ignore
    fetch({ commit, dispatch }) {
        CategoryService.list()
            .then(res => {
                commit("SET_CATEGORIES", res.data);
                commit("SET_CATEGORIES_COUNT", res.headers["x-total-count"]);
            })
            .catch(err => {
                //console.log(err.response);
                const notification = {
                    type: "error",
                    message: "there was a problem while creating an user: " + err.message
                };
                //dispatch("notification/add", notification, { root: true });
            });
        SizeService.list()
            .then(res => {
                commit("SET_SIZES", res.data);
                commit("SET_SIZES_COUNT", res.headers["x-total-count"]);
            })
            .catch(err => {
                //console.log(err.response);
                const notification = {
                    type: "error",
                    message: "there was a problem while creating an user: " + err.message
                };
                //dispatch("notification/add", notification, { root: true });
            });
    },

    // @ts-ignore
    reset({ commit }) {
        commit("RESET_PROPS");
    },

};

