/* eslint-disable */

import Vue from "vue";
import Vuex from "vuex";

import * as product from "./modules/product";
import * as session from "./modules/session";
 import * as supplier from "./modules/supplier";
import * as itemProps from "./modules/itemProps";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sizes: [],
    categories: [],
  },
  mutations: {},
  actions: {},
  modules: {
    product,
    supplier,
    session,
    itemProps,
  }
});