import Sale from "@/models/sale";
import http from "@/services/http-common";

class SaleService {

    list() {
        return http.get("/sales");
    }

    create(sale: Sale) {
        return http.post("/sales", sale )
    }

    update(sale: Sale) {
        return http.put(`/sales/${sale.id}`, sale)
    }

    delete(id: number) {
        return http.delete(`/sales/${id}`)
    }
}

export default new SaleService();
