<template>
  <div class="navbar-container">
    <b-navbar>
      <template slot="brand">
        <b-navbar-item class="brand">
          <img src="@/assets/desapegos.jpg" />
          <p>DESAPEGOS {{ dspgName }}</p>
          <span>|</span>
        </b-navbar-item>
      </template>

      <template slot="start" v-if="loggedIn">
        <b-navbar-item>
          <router-link to="/instagram">INSTAGRAM</router-link>
        </b-navbar-item>

        <b-navbar-item>
          <router-link to="/products">PRODUTOS</router-link>
        </b-navbar-item>

        <b-navbar-item>
          <router-link to="/sales">VENDAS & OPERAÇÕES</router-link>
        </b-navbar-item>

        <b-navbar-item>
          <router-link to="/suppliers">MAMÃES</router-link>
        </b-navbar-item>

        <b-navbar-item>
          <router-link to="/configurations">CONFIGURAÇÕES</router-link>
        </b-navbar-item>

      </template>
      <template slot="start" v-if="!loggedIn">
        <b-navbar-item>
          <router-link to="/">INÍCIO</router-link>
        </b-navbar-item>

        <b-navbar-item v-show="false">
          <router-link to="/about">SOBRE</router-link>
        </b-navbar-item>

        <b-navbar-item>
          <router-link to="/pricing" v-show="false">PREÇOS</router-link>
        </b-navbar-item>

      </template>

      <template slot="end">
        <b-navbar-item tag="div">
          <div class="user-sessions-container" v-if="!loggedIn">
            <!-- <b-button outlined type="is-success">CADASTRAR</b-button> -->
            <b-navbar-item>
              <a href="https://radunz.dev/contato/">solicitar uma avaliação</a>
            </b-navbar-item>
            <b-button v-show="false" type="is-success" @click="openTrialModal">Solicitar uma avaliação</b-button>
            <b-button outlined type="is-primary" @click="openLoginModal">Entrar</b-button>
          </div>
          <div class="user-sessions-container" v-if="loggedIn">
            <p> Olá, {{userName}}!</p>
            <!--
            <b-tooltip label="Alterar as configurações do seu ambiente ou sua conta"
                       position="is-left">
              <router-link to="/configurations">
                <img class="avatar" src="/img/nah_cristine.jpg" alt="Avatar de Najara"/>
              </router-link>
            </b-tooltip>
            -->
            <img class="avatar" src="/img/nah_cristine.jpg" alt="Avatar de Najara"/>
            <a @click="logOut">SAIR</a>
          </div>


        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import ModalCreateSession from "@/components/modals/ModalCreateSession";
import Product from "@/models/product";
import ModalTrialRequest from "@/components/modals/ModalTrialRequest";

export default {
  props: {
    title: String
  },
  computed: {
    currentView: function() {
      return this.$route.meta.display;
    },
    loggedIn: function () {
      // `this` aponta para a instância Vue da variável `vm`
      return this.$store.state.session.loggedIn;
    },
    dspgName: function () {
      if (this.loggedIn) {
        return ' - Davi & Julia,'
      }
      else {
        return ''
      }
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch("session/logout" )
      this.$router.push('/');
      },
    openTrialModal: function (){
      this.$buefy.modal.open({
        parent: this,
        component: ModalTrialRequest,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
      });

    },
    openLoginModal: function() {
      this.$buefy.modal.open({
        parent: this,
        component: ModalCreateSession,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
      });
    }
  },
  data() {
    return {
      userName: 'Najara'
    };
  }
};
</script>

<style lang="scss" scoped>
.navbar-container {
  padding: 5px 0;
  width: 100%;
  margin: 0 auto;
  height: 20px;
}

.router-link-exact-active {
  color: $primary;
  font-weight: bold;
  text-decoration: underline grey;
}

h5 {
  color: #8d8d8d;
  text-align: start;
  padding-top: 15px;
  padding-left: 20px;
}

hr {
  margin: 3px;
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-width: 1px;
}

.button {
  color: whitesmoke;
  font-weight: bold;
  padding: 10px 15px;
  background: #d3455b;
  font-family: "Dosis", sans-serif;
  font-size: 1em;
  text-transform: uppercase;
}

.user-sessions-container {
  display: flex;
  flex-direction: row;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
}

.user-sessions-container button {
  margin: 0 5px;
}

.avatar {
  border-radius: 5px;
  margin: 0 10px;
}

.brand{
  cursor: unset;
}

.brand p {
  margin: 0 10px;
  color: #d3455b;
}
</style>
