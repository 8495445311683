/* eslint-disable */
import SessionService from "@/services/SessionService";
import user from "@/models/user";
import Credentials from "@/models/credentials";
import axios from 'axios';

// ACTIONS, MUTATIONS AND GETTERS ARE
// ALWAYS REGISTERED UNDER THE GLOBAL NAMESPACE
// ... Using namespaced, prevents this behaviour.
export const namespaced = true;

export const state = {
    loggedIn: false,
    user: {},
    token: ''
};

export const mutations = {
    SET_USER(state: any, usr: user) {
        state.user = usr;
    },
    SET_SESSION(state: any, token: string) {
        state.token = token;
        state.loggedIn = true
        localStorage.setItem('user', JSON.stringify(state.user));
        localStorage.setItem('token', state.token);
    },
    RESET_SESSION(state: any) {
        state.loggedIn = false;
        state.user = null
        state.token = null
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        location.reload()
    },
    CLEAR_SESSION(state: any) {
        state.loggedIn = false;
        state.user = null
        state.token = null
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    },
};

export const actions = {

    // @ts-ignore
    register({ commit, dispatch }, credentials) {
        SessionService.signUp(credentials)
            .then(res => {
                commit("SET_USER", res.data);
            })
            .catch(err => {
                //console.log(err.response);
                const notification = {
                    type: "error",
                    message: "there was a problem while creating an user: " + err.message
                };
                //dispatch("notification/add", notification, { root: true });
            });
    },

    // @ts-ignore
    login({ commit, rootState, dispatch }, user: object) {
        SessionService.logIn( user )
            .then( res => {
                commit("SET_USER", res.data);
                commit("SET_SESSION", res.headers["authorization"]);
                const notification = {
                    type: "success",
                    message: "Your session has been created!"
                };
                return res;
                //dispatch("notification/add", notification, { root: true });
            })
            .catch(err => {
                const notification = {
                    type: "error",
                    message: "there was a problem logging in: " + err.message
                };
                //dispatch("notification/add", notification, { root: true });
                throw err;
            });
    },

    // @ts-ignore
    logout({ commit, getters, dispatch }) {
        SessionService.logOut()
            .then(res => {
                commit("RESET_SESSION");
            })
            .catch(err => {
                const notification = {
                    type: "error",
                    message: "there was a problem logging out: " + err.message
                };
                    //dispatch("notification/add", notification, { root: true });
            });
    },

    // @ts-ignore
    clear({ commit }) {
        commit("CLEAR_SESSION");
    },

    // @ts-ignore
    restore({ commit, dispatch }, session: object) {
        // @ts-ignore
        commit("SET_USER", session.user);
        // @ts-ignore
        commit("SET_SESSION", session.token);

        const notification = {
            type: "success",
            message: "Your session has been created!"
        };
        //dispatch("notification/add", notification, { root: true });
    },

};

