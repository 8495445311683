import Category from "@/models/category";
import http from "@/services/http-common";
import Size from "@/models/size";

class CategoryService {
  list() {
    return http.get("/categories");
  }

  create(category: Category) {
    return http.post("/categories", category);
  }

  update(category: any) {
    return http.put(`/categories/${category.id}`, category)
  }

  remove(id: number) {
    return http.delete(`/categories/${id}`)
  }

}

export default new CategoryService();
