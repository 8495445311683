import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import ItemsPage from "../views/ItemListingPage.vue";
import SalesReports from "../views/SalesReportsPage.vue";
import Suppliers from "../views/SuppliersPage.vue";
import ItemDetails from "../views/ItemDetailsPage.vue";
import IncludePage from "../views/ItemIncludePage.vue";
import ConfigurationsPage from "../views/ConfigurationsPage.vue"
import IndexLandingPage from "@/views/Landing/IndexLandingPage.vue";
import AboutLandingPage from "@/views/Landing/AboutLandingPage.vue";
import PricesLandingPage from "@/views/Landing/PricesLandingPage.vue";
import InstagramPostsPage from "@/views/InstagramPostsPage.vue";


import Error401Page from "@/views/Errors/Error401Page.vue";
import Error403Page from "@/views/Errors/Error403Page.vue";
import Error404Page from "@/views/Errors/Error404Page.vue";
import Error500Page from "@/views/Errors/Error500Page.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Index (Landing)",
    component: IndexLandingPage
  },
  {
    path: "/about",
    name: "About (Landing)",
    component: AboutLandingPage
  },
  {
    path: "/pricing",
    name: "Prices (Landing)",
    component: PricesLandingPage
  },
  {
    path: "/products",
    name: "ItemsPage",
    component: ItemsPage
  },
  {
    path: "/instagram",
    name: "InstagramPage",
    component: InstagramPostsPage
  },
  {
    path: "/details/:id",
    name: "ItemDetails",
    component: ItemDetails
  },
  {
    path: "/sales",
    name: "SalesReports",
    component: SalesReports
  },
  {
    path: "/suppliers",
    name: "Suppliers",
    component: Suppliers
  },
  {
    path: "/include",
    name: "Inlcude",
    component: IncludePage
  },
  {
    path: "/configurations",
    name: "Configurations",
    component: ConfigurationsPage
  },
  {
    path: "/401",
    name: "Unauthorized (Error)",
    component: Error401Page
  },
  {
    path: "/403",
    name: "No Permission (Error)",
    component: Error403Page
  },
  {
    path: "/404",
    name: "Not Found (Error)",
    component: Error404Page
  },
  {
    path: "/500",
    name: "Internal Server Error (Error)",
    component: Error500Page
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
