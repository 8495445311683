<template>
  <div class="container">
    <section class="hero mt-0">
      <div class="hero-body">
        <p class="title has-text-primary">
          desapegos.app
        </p>
        <p class="subtitle has-text-grey mb-3">
          o gerenciador que te ajuda a vender pelas redes sociais
        </p>
        <div class="columns is-vcentered">
          <div class="column">
            <img class="avatar" src="/img/iStock-1178491832.jpg" alt="Mercado de Pulgas"/>
            <!--
            <img class="avatar" src="/img/istockphoto-1276986457-1024x1024.jpg" alt="Mercado de Pulgas"/>
            <img class="avatar" src="/img/istockphoto-1178491832-1024x1024.jpg" alt="Mercado de Pulgas"/>
            <img class="avatar" src="/img/istockphoto-1276986462-1024x1024.jpg" alt="Mercado de Pulgas"/>
            -->
          </div>
          <div class="column m-0 is-three-fifths has-text-centered">
            <p class="is-size-6 has-text-grey is-family-primary mb-3">
              com a integração do instagram, você pode vender mais em menos tempo.<br>
            </p>
            <p class="is-size-6 has-text-grey is-family-primary mb-3">
              você decide o melhor fluxo de trabalho.<br/>
              publique as fotos do produto pelo desapegos
              ou importe os produtos das suas publicações.
            </p>
            <p class="is-size-6 has-text-grey is-family-primary mb-3">
              tenha controle de todos os seus itens a venda.
            </p>
            <p class="is-size-6 has-text-grey is-family-primary mb-3">
              registre peças em consignação sem perder o controle:
              valores já pagos ou valores devidos, por ambas as partes.
            </p>
            <p class="is-size-6 has-text-grey is-family-primary mb-3">
              consulte registros históricos de vendas, compras e lucro.
            </p>
            <p class="is-size-6 has-text-grey is-family-primary mb-3">
              identifique facilmente as categorias que são mais vendidas.
            </p>
            <p class="is-size-6 has-text-grey is-family-primary mb-6">
              confirme a venda apenas após receber o dinheiro.
            </p>
            <span class="mt-6 has-text-centered">
              <p class="has-text-centered">
                <a href="https://radunz.dev/contato/">Deseja saber mais?</a>
              </p>
            </span>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
name: "IndexLandingPage"
}
</script>

<style scoped>

.grey-sub {
  color: darkgray;
}

</style>