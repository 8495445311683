<template>
  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">NOVO PRODUTO</p>
      </header>
      <section class="modal-card-body">
        <br />
        <p class="has-text-success">Você adicionou um novo produto!</p>
        <br />
        <p>O novo produto foi registrado com a tag:</p>
        <br />
        <p class=" has-text-centered is-size-3">
          <span class="has-text-weight-semibold has-text-danger">{{
            tag
          }}</span>
        </p>
        <br />

        <p>
          Marque seu produto com este identificador e clique em OK para
          retornar.
        </p>
        <br />
      </section>
      <footer class="modal-card-foot">
        <div class="modal-card-actions">
          <div v-show="!published">
            <span v-show="!publishError">
          <b-button class="button is-info is-outlined" @click="publish" :loading="sending"
            >Publicar!</b-button
          >
            </span>

            <span v-show="publishError">
              <b-button class="button is-danger is-outlined" :disabled="true"
              >FALHA!</b-button
              >
            </span>
          </div>
          <div v-show="published & !publishError">
            <p>Publicado!</p>
          </div>
          <b-button class="button is-success is-outlined" @click="confirm"
          >OK, marquei!</b-button
          >
        </div>
        <div class="modal-card-actions">

          <b-button class="button is-success" @click="addNew"
            >Adicionar outro</b-button
          >
        </div>
      </footer>
    </div>
  </form>
</template>

<script>
import InstagramService from "@/services/InstagramService.ts";

export default {
  name: "ModalConfirmAddition",
  props: ["product"],
  mounted() {
    this.tag = this.product.tag;
  },
  data() {
    return {
      tag: "",
      published: false,
      sending: false,
      publishError: false
    };
  },
  methods: {
    ok: function() {
      this.tag = this.product.tag;
    },
    confirm: function() {
      this.$emit("close");
      this.$router.push("/products");

      const notif = this.$buefy.notification.open({
        duration: 1000,
        message: `Produto adicionado com sucesso!.`,
        type: 'is-success',
        hasIcon: true
      })

    },
    addNew: function() {
      this.$emit("continue");
      this.$emit("close");

      const notif = this.$buefy.notification.open({
        duration: 1000,
        message: `Produto adicionado com sucesso!.`,
        type: 'is-success',
        hasIcon: true
      })

    },
    publish: function() {
      this.sending = true
      InstagramService.post(this.product)
        .then(response => {
          this.published = true;
          this.sending = false
          console.log(response.data);
          const notif = this.$buefy.notification.open({
            duration: 2500,
            message: `Produto publicado no Instagram!.`,
            type: 'is-info',
            hasIcon: true
          })
        })
        .catch(e => {
          console.log(e);
          this.published = false;
          this.publishError = true;
          const notif = this.$buefy.notification.open({
            duration: 2500,
            message: `Ocorreu um erro ao publicar no Instagram!<br/>Tente novamente mais tarde acessando os detalhes do produto.`,
            type: 'is-danger',
            hasIcon: true
          })
        });
    }
  }
};
</script>

<style scoped>
.modal-card-foot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.modal-card-actions {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-content: end;
}

.modal-card-actions button {
  margin-bottom: 12px;
}

</style>
