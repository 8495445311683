<template>
  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">CONFIRMAR REMOÇÃO</p>
      </header>

      <section class="modal-card-body">
          <br />
          <span class="has-text-weight-semibold has-text-danger"
            >Você está removendo um valor da tabela {{ this.desc }}!</span
          >
          <br />
        <br />
          <p>Confirma a remoção do valor <span class="has-text-weight-semibold">{{ this.objValue }}</span> ?</p>
          <br />
      </section>

      <footer class="modal-card-foot">
        <b-button
          class="button is-dark"
          outlined
          type="button"
          @click="$emit('close')"
          >CANCELAR</b-button
        >
        <b-button
          class="button is-danger"
          @click="confirm"
          :loading="sending"
          >CONFIRMAR</b-button
        >
      </footer>
    </div>
  </form>
</template>

<script>
import SizeService from "@/services/SizeService";
import CategoryService from "@/services/CategoryService";

export default {
  name: "ModalConfirmConfigurationRemoval",
  props: ["id", "object", "type", "desc"],
  data() {
    return {
      sending: false,
    };
  },
  computed: {
    objValue: function() {
      if (this.type == 'size') {
        return this.object.age_size
      }
      if (this.type == 'category') {
        return this.object.name
      }
      else {
        return '???'
      }

    }
  },
  methods: {
    confirm: function () {
      if (this.type == 'size') {
        SizeService.remove(this.object.id).then(() => {
          this.$emit("success");
          this.$emit("close");
        });
      }
      if (this.type == 'category') {
        CategoryService.remove(this.object.id).then(() => {
          this.$emit("success");
          this.$emit("close");
        });
      }
    }
  }
};
</script>

<style scoped>

.modal-card-foot {
  display: flex;
  justify-content: space-between;
}
</style>
