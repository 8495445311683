<!-- //TODO: REFATORAR -->
<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">

    <div class="modal-card" style="width: auto">

      <header class="modal-card-head">
        <p class="modal-card-title">CONFIRMAR VENDA</p>
      </header>

      <section class="modal-card-body">
        <br />
        <p class="has-text-success">Opa, mais uma venda. Que maravilha!</p>
        <br />
        <p>
          O valor total desta venda é de
          <span class="has-text-success has-text-weight-bold">
            R$ {{ productsTotalValue | formatValue }}</span
          >, para os produtos de tag:
        </p>
        <p class="product-listing">
           <span
               v-for="product in products"
               :key="product.id"
               class="has-text-weight-semibold has-text-danger"
           >{{ product.tag }},
          </span>
        </p>

        <hr />


        <ValidationProvider
            name="Desconto"
            rules="min_value:0"
            v-slot="{ errors, valid }"
        >
          <b-field label="Deseja conceder algum desconto?"
                   :type="{ 'is-danger': errors[0], 'is-success': valid }"
                   :message="errors">
          <b-numberinput
            v-model="sale.discount"
            type="number"
            placeholder="R$ 0,00"
            :controls="false"
            icon-pack="fas"
            icon="dollar-sign"
          >
          </b-numberinput>
        </b-field>
        </ValidationProvider>

        <b-field label="Deseja identificar a compradora?">
          <b-autocomplete
            v-model="buyerSelection"
            :data="filteredbuyerArray"
            field="searchField"
            placeholder="Procure pelo nome, sobrenome, instagram..."
            clearable
            icon-pack="fas"
            icon="female"
            @select="option => (buyer = option)"
          >
            <template slot="empty">Nenhum resultado encontrado</template>
          </b-autocomplete>
        </b-field>

        <ValidationProvider
            name="Frete"
            rules="min_value:0"
            v-slot="{ errors, valid }"
        >

        <b-field label="Qual é o valor do frete?"
                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                 :message="errors">
          <b-numberinput
            v-model="sale.freight"
            type="number"
            placeholder="R$ 0,00"
            :controls="false"
            icon-pack="fas"
            icon="dollar-sign"
          >
          </b-numberinput>
        </b-field>
        </ValidationProvider>

        <hr />
        <p class="has-text-weight-bold">
          O valor total da venda é de
          <span class="has-text-success">
            R$ {{ saleTotalValue | formatValue }}
          </span>
        </p>

        <p v-if="false">
          Ao confirmar esta ação, os produtos que estão em consignação serão
          removidos e a diferença entre o será adicionado como débito com o
          fornecedor.
        </p>
      </section>

      <footer class="modal-card-foot">
        <b-button
          class="button is-dark"
          outlined
          type="button"
          @click="$emit('close')"
          >CANCELAR</b-button
        >
        <b-button @click="handleSubmit(submit)" :loading="sending"  class="button is-success"
          >VENDER</b-button
        >
      </footer>

    </div>
    </ValidationObserver>
  </div>
</template>

<script>
import Sale from "@/models/sale";
import http from "@/services/http-common";
import { mapState } from "vuex";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  name: "ModalConfirmSale",
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: ["products"],
  data() {
    return {
      sending: false,
      sale: {},
      buyer: {},
      selected: null,
      buyerSelection: ''
    };
  },
  created() {
    this.sale = {};
  },
  computed: {
    filteredbuyerArray: function() {
      if (this.buyerSelection) {
        const exp = new RegExp(this.buyerSelection.trim(), "i");
        return this.suppliers.filter(
          sup => exp.test(sup.name) || exp.test(sup.instagramUsername)
        );
      } else {
        return this.suppliers;
      }
    },
    saleTotalValue: function() {
      return this.productsTotalValue + ((this.sale.freight? this.sale.freight : 0) - (this.sale.discount? this.sale.discount : 0 ));
    },
    productsTotalValue: function() {
      return this.products
        .map(x => x.sellPrice)
        .reduce(function(a, b) {
          return a + b;
        }, 0);
    },
    ...mapState({
      suppliers: state => state.supplier.suppliers
    })
  },
  methods: {
    submit: function() {
      this.sending = true
      if (this.sale.discount == null) {
        this.sale.discount = 0
      }
      if (this.sale.freight == null) {
        this.sale.freight = 0
      }

      if (this.buyerSelection != '' && !( this.buyer.id >1)) {
        const notif = this.$buefy.notification.open({
          duration: 1500,
          message: `Oops! Confira o campo "compradora".`,
          type: 'is-danger',
        })
        this.sending = false;
      }
      else {
        /* eslint-disable  */
        if (this.buyer.id > 1) {
          this.sale.buyer_id = this.buyer.id;
        }
        this.sale.products = this.products.map(x => x.tag);
        console.log('VENDA: ' + this.products);
        http.post("/sales", this.sale).then(result => {
          this.products.forEach((product, index) => {
            this.$store.dispatch("product/sellProduct", product.id).then(() => {
              const notif = this.$buefy.notification.open({
                duration: 1500,
                message: `Muito bom! A venda foi registrada.`,
                type: 'is-success',
              })
              this.sending = false;
              this.$emit('success');
              this.$emit('close');
            }).catch(() => {
              const notif = this.$buefy.notification.open({
                duration: 1500,
                message: `Oops! Ocorreu algum problema durante o salvamento da venda.`,
                type: 'is-danger',
              })
              this.sending = false;
              this.$emit('close');
            })
          });
        });
      }
    }
  },
  filters: {
    formatValue: function (value) {
      return value.toFixed(2)
    }
  },
};
</script>

<style scoped>
.modal-card-foot {
  display: flex;
  justify-content: space-between;
}

.product-listing
{
  margin: 5px 15px;
}
</style>
