<template>
  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">CONFIRMAR REMOÇÃO</p>
      </header>

      <section class="modal-card-body">
        <div v-if="canDeleteProduct">
          <br />
          <p class="has-text-danger">
            Você está removendo itens do seu estoque!
          </p>
          <br />
          <p>
            Você confirma a remoção do produto:
            <span class="has-text-weight-semibold has-text-danger">
              {{ this.product.tag }}
            </span>
            ?
          </p>
          <br />
          <p class="has-text-danger">
            Ao remover um produto, todas as informações associadas serão
            apagadas permanentemente, incluindo fotos e registros de transações!
          </p>
          <br />
          <p>
            Caso queira manter o histórico dessa peça, podemos alterar o valor
            de venda para que seja igual ao valor de compra e realizar a venda
            do produto. Dessa forma, a peça não influenciará nos cálculos de
            lucro mas seu histórico será mantido.
            <span class="has-text-weight-semibold">Deseja continuar?</span>
          </p>
          <br />
        </div>

        <div v-else-if="!canDeleteProduct">
          <br />
          <span class="has-text-weight-semibold has-text-danger"
            >Não é possível remover alguns dos produtos selecionados</span
          >
          <br />
          <br />
          <p>
            Produtos que foram adicionados em modo de consignação não podem ser
            removidos.
          </p>
          <p>
            Para evitar inconsistência de dados, utilize a opção '<span
              class="has-text-weight-semibold has-text-danger"
              >DEVOLVER</span
            >', encontrada na página de produtos
          </p>
          <br />
          <p>
            Apenas itens que foram obtidos por meio de compra podem ser
            removidos do estoque.
          </p>
          <br />
        </div>

        <br />
      </section>

      <footer class="modal-card-foot">
        <b-button
          class="button is-dark"
          outlined
          type="button"
          @click="$emit('close')"
          >CANCELAR</b-button
        >
        <b-button
          class="button is-warning"
          type="button"
          v-show="canDeleteProduct"
          @click="archive()"
          :loading="sending"
          >REMOVER, MANTER HISTÓRICO</b-button
        >
        <b-button
          class="button is-danger"
          @click="remove"
          :disabled="!canDeleteProduct"
          :loading="sending"
        >
          REMOVER
        </b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import transaction from "@/models/transaction";
import TransactionService from "@/services/TransactionService";
import product from "@/models/product";
import ProductService from "@/services/ProductService";

export default {
  name: "ModalConfirmExclusion",
  props: ["product"],
  methods: {
    remove: function() {
      this.sending = true;
      if (this.product.supplier.id == 1) {
        ProductService.delete(this.product.id)
          .then(() => {
            this.$store
              .dispatch("product/removeProduct", this.product.id)
              .then(() => {
                const notif = this.$buefy.notification.open({
                  duration: 1500,
                  message: `O produto "${this.product.name}" foi removido com sucesso!.`,
                  type: "is-info",
                  hasIcon: true
                });
                this.$router.push("/products");
                this.sending = false;
                this.$emit("close");
              });
          })
          .catch(e => {
            const notif = this.$buefy.notification.open({
              duration: 1500,
              message: `O produto "${this.product.name}" não foi removido.`,
              type: "is-danger",
              hasIcon: true
            });
            this.$emit("close");
            this.sending = false;
          });
      }
    },
    archive: function() {
      this.sending = true;
      if (this.product.supplier.id == 1) {
        const trans = new transaction(6, this.product.id);
        TransactionService.create(trans)
          .then(() => {
            this.$store
              .dispatch("product/removeProduct", this.product.id)
              .then(() => {
                const notif = this.$buefy.notification.open({
                  duration: 1500,
                  message: `O produto "${this.product.name}" foi removido com sucesso!.`,
                  type: "is-info",
                  hasIcon: true
                });
                this.$router.push("/products");
                this.sending = false;
                this.$emit("close");
              });
          })
          .catch(() => {
            const notif = this.$buefy.notification.open({
              duration: 1500,
              message: `O produto "${this.product.name}" não foi removido.`,
              type: "is-danger",
              hasIcon: true
            });
            this.$emit("close");
            this.sending = false;
          });
      }
    }
  },
  data() {
    return {
      sending: false
    };
  },
  computed: {
    canDeleteProduct: function() {
      return this.product.supplier.id == 1;
    }
  }
};
</script>

<style scoped>
.modal-card-foot {
  display: flex;
  justify-content: space-between;
}
</style>
