<template>
  <section>
    <p class="section-title">HISTÓRICO DE TRANSAÇÕES / PEÇAS</p>
    <div class="latest-sales">
      <!-- TRANSACTIONS TABLES -->
      <b-table
        :data="isEmpty ? [] : transactions"
        :mobile-cards="true"
        icon-pack="fa"
        :paginated="true"
        :per-page="perPage"
        :current-page="currentPage"
        :pagination-simple="false"
        pagination-position="both"
        ria-next-label="Próxima página"
        aria-previous-label="Página anterior"
        aria-page-label="Página"
        aria-current-label="Página atual"
        searchable
      >
        <b-table-column
          field="id"
          label="ID"
          centered
          numeric
          v-slot="props"
          :visible="false"
          sortable
        >
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="date" label="Data" centered v-slot="props" sortable>
          <span class="has-text-weight-semibold">
            {{ moment(props.row.date).format("DD/MM/YYYY") }}
          </span>
        </b-table-column>

        <b-table-column
          field="operation"
          label="Operação"
          centered
          v-slot="props"
          sortable
        >
          <span
            :class="
              props.row.operation == 1 ||
              props.row.operation == 3 ||
              props.row.operation == 5
                ? 'has-text-danger'
                : 'has-text-success-dark'
            "
          >
            {{ props.row.operation | convertOperation }}
          </span>
        </b-table-column>

        <b-table-column field="product_id" label="TAG" centered v-slot="props" sortable>
          <span class="">
            <span class="tag is-primary mx-1 has-text-white">{{
              props.row.product.tag
            }}</span>
          </span>
        </b-table-column>

        <b-table-column
          field="product.name"
          label="Produto"
          centered
          v-slot="props"
          sortable
        >
          <span class="">
            {{ props.row.product.name }}
          </span>
        </b-table-column>

        <b-table-column
          field="product.category.name"
          label="Gênero / Categoria"
          centered
          v-slot="props"
          sortable
        >
          <span class="">
            {{ props.row.product.category.name }},
            {{ props.row.product.gender | convertGender }}
          </span>
        </b-table-column>

        <b-table-column field="product.size.name" label="Tamanho" centered v-slot="props" sortable>
          <span class="">
            {{ props.row.product.size.name }}
          </span>
        </b-table-column>

        <!--
        <b-table-column field="supplier.name" label="Fornecedor" centered v-slot="props">
          {{ props.row.supplier.name }}
        </b-table-column>
        -->

        <b-table-column
          field="balance"
          label="Resultado"
          centered
          v-slot="props"
          sortable
        >
          <span
            class="has-text-weight-semibold"
            :class="
              props.row.operation == 1 ||
              props.row.operation == 3 ||
              props.row.operation == 5
                ? 'has-text-danger'
                : 'has-text-success-dark'
            "
          >
            R$ {{ props.row.balance | formatValue }}
          </span>
        </b-table-column>
      </b-table>
    </div>
  </section>
</template>

<script>
import TransactionService from "@/services/TransactionService";

export default {
  name: "LatestTransactions",
  data: function() {
    return {
      isEmpty: false,
      isLoading: false,
      currentPage: 1,
      perPage: 15,
      transactions: [],
    };
  },
  filters: {
    convertOperation: function(value) {
      if (value === 1) return "Compra";
      if (value === 2) return "Venda";
      if (value === 3) return "Consignação";
      if (value === 4) return "Devolução";
      if (value === 5) return "Acerto";
      if (value === 6) return "Remoção";
      else return "";
    },
    convertGender: function(value) {
      if (value === 0) return "Unisex";
      if (value === 1) return "Masculino";
      if (value === 2) return "Feminino";
      else return "";
    },
    formatValue: function(value) {
      return value.toFixed(2);
    }
  },
  methods: {
    fetchTransactions: function() {
      TransactionService.list()
        .then(response => {
          this.transactions = response.data;
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  created() {
    this.fetchTransactions();
  }
};
</script>

<style scoped>

.section-title {
  font-family: sans-serif;
  margin-top: 20px;
  padding-top: 12px;
  padding-left: 20px;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  color: #4a4a4a;
}

.latest-sales {
  margin-top: 24px;
  width: 100%;
  box-shadow: 0px 1px 22px -12px #607d8b;
  background-color: #fff;
  padding: 10px;
  position: relative;
  border: 1px solid #dedede;
  border-radius: 3px;
  min-height: 500px;
  margin-bottom: 40px;
}

</style>
